var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAlarmingDataAccess } from '@features/alarmingData/useAlarmingDataAccess';
export const AlarmingDataButton = (_a) => {
    var { userOrInvitationId, farmSeasonId, children, sx, size = 'small' } = _a, rest = __rest(_a, ["userOrInvitationId", "farmSeasonId", "children", "sx", "size"]);
    const { canAccessAlarmingDataPage, alarmingDataPagePath } = useAlarmingDataAccess({
        id: userOrInvitationId,
        farmSeasonId,
    });
    return (_jsx(_Fragment, { children: canAccessAlarmingDataPage && (_jsx(Button, Object.assign({ sx: Object.assign({ minWidth: 'auto', padding: 3 }, sx), size: size, component: Link, to: alarmingDataPagePath, target: "_blank", rel: "noopener noreferrer" }, rest, { children: children }))) }));
};
