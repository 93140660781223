import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconChevronRight, IconGraph } from '@components/Icons';
import { formatDate } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
import { ResultsPublicationButton } from '@features/results-publication/ResultsPublicationButton/ResultsPublicationButton';
import { SeeResultsButton } from '@features/results-publication/SeeResultsButton/SeeResultsButton';
import { useFarmSeasonStatusPanelStyles } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel.style';
import { RESULTS_CALCULATION_STATUS } from '@/types/farmSeason.types';
import { useIsFarmSeasonEditable } from '@features/farmer/profile/useIsFarmSeasonEditable';
export const ResultsPublicationStep = ({ farmSeason, user, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    const { classes } = useFarmSeasonStatusPanelStyles();
    const isoCode = (_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en';
    const { isEditable } = useIsFarmSeasonEditable({ user, farmSeason });
    const isStepCompleted = !!farmSeason.results_published_at && !farmSeason.results_unpublished_at;
    const isPreviousStepCompleted = !!farmSeason.results_generated_at &&
        farmSeason.results_calculation_status === RESULTS_CALCULATION_STATUS.SUCCEEDED;
    const statusText = farmSeason.results_unpublished_at
        ? t('farmers.results.unpublished_at', { date: formatDate(isoCode, farmSeason.results_unpublished_at) })
        : isStepCompleted
            ? t('farmers.results.published_at', { date: formatDate(isoCode, farmSeason.results_published_at) })
            : isPreviousStepCompleted
                ? t('farmers.results.to-be-published')
                : null;
    const iconColor = isStepCompleted
        ? 'success'
        : isPreviousStepCompleted
            ? 'primary'
            : 'disabled';
    return (_jsx(FarmerProfileTimelineStep, { show: true, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: iconColor }) : _jsx(IconGraph, { color: iconColor }), label: t('statuses.timeline.results-publication'), author: (_b = farmSeason.results_unpublished_by) !== null && _b !== void 0 ? _b : farmSeason.results_published_by, statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: isPreviousStepCompleted, isLastStep: true, extra: _jsxs("div", { className: classes.statusActions, children: [isEditable && (_jsx(ResultsPublicationButton, { farmSeasonId: farmSeason.id, hasResults: isPreviousStepCompleted, isPublished: !!farmSeason.results_published_at })), _jsx(SeeResultsButton, { sx: { textDecoration: 'underline', padding: 0, '&:hover': { background: 'none' } }, variant: "text", endIcon: _jsx(IconChevronRight, { sx: { width: 12 } }), carbonModel: farmSeason.carbon_model, farmSeasonId: farmSeason.id, resultsPublishedAt: farmSeason.results_published_at, children: t('farmers.results.see-results') })] }) }));
};
