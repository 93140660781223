var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconInfo, IconWarning } from '@components/Icons';
import { formatDate } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
import { IconRefresh } from '@components/Icons/IconRefresh';
import { CARBON_MODEL, RESULTS_CALCULATION_STATUS } from '@/types/farmSeason.types';
import { Banner } from '@components/Banner';
import { Button, CircularProgress } from '@mui/material';
import { useLazyGetRawDndcLogsQuery } from '@services/results.api';
import { useIsFarmSeasonEditable } from '@features/farmer/profile/useIsFarmSeasonEditable';
export const ResultsGenerationStep = ({ farmSeason, user, }) => {
    var _a;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    const [getRawLogs, { isLoading: isLoadingRawLogs }] = useLazyGetRawDndcLogsQuery();
    const { isEditable } = useIsFarmSeasonEditable({ user, farmSeason });
    const shouldDisplayResultsYoYBanner = !farmSeason.is_baseline && farmSeason.carbon_model === CARBON_MODEL.DNDC;
    const isStepCompleted = !!farmSeason.results_generated_at &&
        farmSeason.results_calculation_status === RESULTS_CALCULATION_STATUS.SUCCEEDED;
    const isPreviousStepCompleted = !!farmSeason.encoding_verified_at;
    const statusText = !isPreviousStepCompleted
        ? null
        : isStepCompleted
            ? formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', farmSeason.summary_validated_at)
            : shouldDisplayResultsYoYBanner
                ? t('labels.waiting')
                : farmSeason.results_calculation_status === RESULTS_CALCULATION_STATUS.UNPROCESSED
                    ? t('labels.not-started')
                    : farmSeason.results_calculation_status === RESULTS_CALCULATION_STATUS.ONGOING
                        ? t('farmers.results.calculation')
                        : t('farmers.results.calculation-failed');
    const iconColor = isStepCompleted
        ? 'success'
        : isPreviousStepCompleted
            ? 'primary'
            : 'disabled';
    const handleDownloadRawLogs = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getRawLogs({ farmSeasonId: farmSeason.id });
    });
    return (_jsx(FarmerProfileTimelineStep, { show: true, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: iconColor }) : _jsx(IconRefresh, { color: iconColor }), label: t('statuses.timeline.results-generation'), author: farmSeason.results_generated_by, statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: isPreviousStepCompleted, extra: _jsxs(_Fragment, { children: [shouldDisplayResultsYoYBanner && (_jsx(Banner, { Icon: _jsx(Banner.Icon, { icon: _jsx(IconInfo, { sx: { width: 16, color: 'white' } }), variant: "info" }), variant: "info", children: _jsx(Banner.Body, { children: t('farmers.results.yoy-results-generation-banner', {
                            year: farmSeason.harvest_year + 1,
                        }) }) })), !farmSeason.results_generated_at &&
                    farmSeason.results_calculation_status === RESULTS_CALCULATION_STATUS.FAILED && (_jsx(Banner, { Icon: _jsx(Banner.Icon, { icon: _jsx(IconWarning, { sx: { width: 16, color: 'white' } }), variant: "error" }), variant: "error", Action: isEditable ? (_jsx(Button, { disabled: isLoadingRawLogs, variant: "text", sx: { textDecoration: 'underline' }, onClick: handleDownloadRawLogs, children: isLoadingRawLogs ? (_jsx(CircularProgress, { size: 16 })) : (t('farmers.results.logs-export')) })) : (_jsx(_Fragment, {})), children: _jsx(Banner.Body, { sx: { fontWeight: 'bold' }, children: t('errors.results-calculation-failed') }) }))] }) }));
};
