var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useAppDispatch, usePermission, useToggle } from '@hooks';
import { CARBON_MODEL, RESULTS_CALCULATION_STATUS } from '@/types/farmSeason.types';
import { composeEventHandlers } from '@utils';
import { VerifyEncodingModal } from '@features/verify-encoding/VerifyEncodingModal/VerifyEncodingModal';
import { useCallback } from 'react';
import { useGenerateResults } from '@features/results-generation/useGenerateResults';
import { useVerifyEncoding } from '@features/verify-encoding/useVerifyEncoding';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TooltipSc } from '@components/TooltipSc';
import { useGetFarmSeasonAgroChecks } from '@features/alarmingData/useGetFarmSeasonAgroChecks';
import { useGenerateAgroChecks } from '@features/alarmingData/useGenerateAgroChecks';
import { ERRORS, setError } from '@/errorSlice';
export const VerifyEncodingButton = (_a) => {
    var { farmSeasonId, farmId, encodingValidatedAt, carbonModel, isBaseline, resultsCalculationStatus, children, onClick } = _a, props = __rest(_a, ["farmSeasonId", "farmId", "encodingValidatedAt", "carbonModel", "isBaseline", "resultsCalculationStatus", "children", "onClick"]);
    const { t } = useTranslation();
    const { canConfirmEncoding } = usePermission();
    const { open, handleOpen, handleClose } = useToggle();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { agroChecks, isLoadingAgroChecks } = useGetFarmSeasonAgroChecks({
        farmSeasonId,
        processHistory: isBaseline,
    });
    const { generateAgroChecks, isGeneratingAgroChecks } = useGenerateAgroChecks({
        onError: () => enqueueSnackbar(t('errors.could-not-generate-agro-checks'), { variant: 'error' }),
    });
    const { verifyEncoding, isVerifyingEncoding } = useVerifyEncoding({
        onSuccess: () => enqueueSnackbar(t('snackbar.encoding-verified'), { variant: 'success' }),
        onError: () => enqueueSnackbar(t('errors.could-not-verify-encoding'), { variant: 'error' }),
    });
    const { generateResults, isGeneratingResults } = useGenerateResults({
        onError: () => enqueueSnackbar(t('errors.could-not-generate-results'), { variant: 'error' }),
    });
    const isEncodingCompleted = !!encodingValidatedAt;
    const showConfirmButton = canConfirmEncoding && isEncodingCompleted;
    const shouldTriggerResultsGeneration = carbonModel === CARBON_MODEL.CFT || isBaseline;
    const unresolvedAgroChecks = agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.filter((agroCheck) => !agroCheck.resolved_at);
    const isLoading = isGeneratingAgroChecks || isVerifyingEncoding || isGeneratingResults || isLoadingAgroChecks;
    const isDisabled = (shouldTriggerResultsGeneration && resultsCalculationStatus === RESULTS_CALCULATION_STATUS.ONGOING) ||
        isLoading ||
        !!(unresolvedAgroChecks === null || unresolvedAgroChecks === void 0 ? void 0 : unresolvedAgroChecks.length);
    const tooltipMessage = isDisabled
        ? resultsCalculationStatus === RESULTS_CALCULATION_STATUS.ONGOING
            ? t('tooltips.results-calculation-is-still-ongoing')
            : t('tooltips.agro-checks-unresolved-left')
        : '';
    const handleClick = useCallback(composeEventHandlers(onClick, handleOpen), []);
    const onConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        const { success: hasGeneratedAgroChecks, agroChecks } = yield generateAgroChecks({
            farmSeasonId,
            processHistory: isBaseline,
        });
        if (!hasGeneratedAgroChecks)
            return handleClose();
        const unresolvedAgroChecks = agroChecks.filter((a) => !a.resolved_at);
        if (unresolvedAgroChecks.length > 0) {
            dispatch(setError({ type: ERRORS.AGRO_CHECKS_UNRESOLVED, message: t('errors.agro-checks-unresolved') }));
            return handleClose();
        }
        const { success: hasVerifiedEncoding } = yield verifyEncoding({ farmSeasonId });
        if (!hasVerifiedEncoding)
            return handleClose();
        if (shouldTriggerResultsGeneration)
            yield generateResults({ farmId, farmSeasonId, carbonModel });
        return handleClose();
    });
    return (_jsxs(_Fragment, { children: [showConfirmButton && (_jsx(TooltipSc, { text: tooltipMessage, display: "flex", placement: "bottom", children: _jsx(Button, Object.assign({ disabled: isDisabled, onClick: handleClick }, props, { children: children })) })), _jsx(VerifyEncodingModal, { open: open, onClose: handleClose, onConfirm: onConfirm, isLoading: isLoading })] }));
};
