import { useEffect } from 'react';
import { useGetFarmSeasonAgroChecks } from '@features/alarmingData/useGetFarmSeasonAgroChecks';
import { useGenerateAgroChecks } from '@features/alarmingData/useGenerateAgroChecks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
export const useDataVerificationStep = ({ farmSeason }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { canSeeAgroChecks, agroChecks, isLoadingAgroChecks } = useGetFarmSeasonAgroChecks({
        farmSeasonId: farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id,
        processHistory: farmSeason.is_baseline,
    });
    const { generateAgroChecks, isGeneratingAgroChecks } = useGenerateAgroChecks({
        onError: () => enqueueSnackbar(t('errors.could-not-generate-agro-checks'), { variant: 'error' }),
    });
    useEffect(() => {
        if (canSeeAgroChecks &&
            !!farmSeason.summary_validated_at &&
            !farmSeason.results_generated_at &&
            !isGeneratingAgroChecks &&
            !isLoadingAgroChecks &&
            (agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.length) === 0) {
            generateAgroChecks({ farmSeasonId: farmSeason.id, processHistory: farmSeason.is_baseline });
        }
    }, [
        canSeeAgroChecks,
        isGeneratingAgroChecks,
        isLoadingAgroChecks,
        farmSeason.summary_validated_at,
        agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.length,
    ]);
    return { agroChecks, generateAgroChecks, isGeneratingAgroChecks, isLoadingAgroChecks };
};
