import { useParams } from 'react-router-dom';
import { useGetFarmerProfileInfos } from '@features/farmer/profile/hooks/useGetFarmerProfileInfos';
import { ROLE_GROUP, useUserRoleGroup } from '@hooks';
import { useGetFarmSeasons } from '@features/farmSeason/useGetFarmSeasons';
export const useFarmerDataComputation = () => {
    var _a, _b, _c, _d, _e;
    const { farmSeasonId: farmSeasonIdParam, id: userOrInvitationIdParam } = useParams();
    const farmSeasonId = farmSeasonIdParam ? Number(farmSeasonIdParam) : undefined;
    const userOrInvitationId = userOrInvitationIdParam ? Number(userOrInvitationIdParam) : undefined;
    const { roleGroup } = useUserRoleGroup();
    const fetchParam = roleGroup === ROLE_GROUP.PARTNER ? { invitationId: userOrInvitationId } : { userId: userOrInvitationId };
    const { data: profile, isLoading: isLoadingInfos } = useGetFarmerProfileInfos(fetchParam);
    const { data: farmSeasons, isLoading: isLoadingFarmSeasons } = useGetFarmSeasons(fetchParam);
    const selectedFarmSeasonData = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.find((fs) => (fs === null || fs === void 0 ? void 0 : fs.id) === farmSeasonId &&
        (fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) !== undefined &&
        (fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) !== null &&
        (fs === null || fs === void 0 ? void 0 : fs.harvest_year));
    const selectedFarmSeason = selectedFarmSeasonData
        ? {
            id: Number(selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.id),
            carbonProgrammeYear: selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.carbon_programme_year,
            harvestYear: selectedFarmSeasonData === null || selectedFarmSeasonData === void 0 ? void 0 : selectedFarmSeasonData.harvest_year,
        }
        : null;
    const farmer = ((_a = profile === null || profile === void 0 ? void 0 : profile.user) === null || _a === void 0 ? void 0 : _a.id) && (profile === null || profile === void 0 ? void 0 : profile.farm_user_id)
        ? {
            id: Number(profile.farm_user_id),
            userId: Number(profile.user.id),
            invitationId: ((_b = profile.user) === null || _b === void 0 ? void 0 : _b.invitation_id) ? Number(profile.user.invitation_id) : undefined,
            firstName: (_c = profile === null || profile === void 0 ? void 0 : profile.user) === null || _c === void 0 ? void 0 : _c.first_name,
            lastName: (_d = profile === null || profile === void 0 ? void 0 : profile.user) === null || _d === void 0 ? void 0 : _d.last_name,
            farmSeasons: ((_e = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.filter((fs) => (fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) !== undefined &&
                (fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year) !== null &&
                (fs === null || fs === void 0 ? void 0 : fs.harvest_year) &&
                (fs === null || fs === void 0 ? void 0 : fs.id))) === null || _e === void 0 ? void 0 : _e.map((fs) => ({
                id: Number(fs.id),
                carbonProgrammeYear: fs === null || fs === void 0 ? void 0 : fs.carbon_programme_year,
                harvestYear: fs === null || fs === void 0 ? void 0 : fs.harvest_year,
            }))) || [],
        }
        : undefined;
    const isLoading = isLoadingInfos || isLoadingFarmSeasons;
    return { isLoading, selectedFarmSeason, farmer };
};
