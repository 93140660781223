var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePermission, useToggle } from '@hooks';
import { IconCrossedOutEye } from '@components/Icons';
import { useResultPublication } from '@features/results-publication/useResultPublication';
import { PublishResultsModal } from '@features/results-publication/PublishResultsModal/PublishResultsModal';
import { UnpublishResultsModal } from '@features/results-publication/UnpublishResultsModal/UnpublishResultsModal';
export const ResultsPublicationButton = (_a) => {
    var { farmSeasonId, hasResults, isPublished } = _a, props = __rest(_a, ["farmSeasonId", "hasResults", "isPublished"]);
    const { t } = useTranslation();
    const { canPublishResults } = usePermission();
    const { open: openPublishModal, handleOpen: onPublishModalOpen, handleClose: onPublishModalClose } = useToggle();
    const { open: openUnpublishModal, handleOpen: onUnpublishModalOpen, handleClose: onUnpublishModalClose, } = useToggle();
    const { changeResultPublication, isLoading } = useResultPublication();
    const onConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        yield changeResultPublication({ farmSeasonId, published: !isPublished });
        onPublishModalClose();
        onUnpublishModalClose();
    });
    return (_jsxs(_Fragment, { children: [canPublishResults && hasResults && (_jsx(Button, Object.assign({ color: isPublished ? 'error' : 'primary', variant: isPublished ? 'outlined' : 'contained', startIcon: isPublished ? _jsx(IconCrossedOutEye, { color: "error" }) : null, onClick: isPublished ? onUnpublishModalOpen : onPublishModalOpen }, props, { children: t(isPublished ? 'farmers.results.unpublish' : 'farmers.results.publish') }))), _jsx(PublishResultsModal, { open: openPublishModal, onClose: onPublishModalClose, onConfirm: onConfirm, isLoading: isLoading }), _jsx(UnpublishResultsModal, { open: openUnpublishModal, onClose: onUnpublishModalClose, onConfirm: onConfirm, isLoading: isLoading })] }));
};
