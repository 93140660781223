import { api } from '@services';
export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateUser: builder.mutation({
            query: (userData) => ({
                url: `/private/users/${userData.userId}`,
                method: 'PUT',
                body: userData.body,
            }),
        }),
        deactivateUser: builder.mutation({
            query: ({ userId }) => ({
                url: `/private/users/${userId}/deactivate`,
                method: 'PUT',
            }),
            invalidatesTags: ['Farmer'],
        }),
        getUserById: builder.query({
            query: (userId) => {
                return {
                    url: `/private/users/${userId}`,
                    method: 'GET',
                };
            },
        }),
    }),
});
export const { useUpdateUserMutation, useGetUserByIdQuery, useDeactivateUserMutation } = userApi;
