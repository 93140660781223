import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFarmerProfilePageStyles } from '@/pages/FarmerProfilePage/FarmerProfilePage.style';
import { FarmerProfileTopNav } from '@features/farmer/profile/components/FarmerProfileTopNav/FarmerProfileTopNav';
import { FarmerProfileInfos } from '@features/farmer/profile/components/FarmerProfileInfos/FarmerProfileInfos';
import { FarmerProfileTimeline } from '@features/farmer/profile/components/FarmerProfileTimeline/FarmerProfileTimeline';
import { useGetFarmerProfileInfos } from '@features/farmer/profile/hooks/useGetFarmerProfileInfos';
import { useGetFarmerProfileFarmSeasons } from '@features/farmer/profile/hooks/useGetFarmerProfileFarmSeasons';
import { FARM_SEASON_STATUS } from '@/types/farmSeason.types';
import { FarmerProfilePageSkeleton } from '@/pages/FarmerProfilePage/FarmerProfilePageSkeleton';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { COHORTS } from '@/types/carbonProgramme.types';
export const FarmerProfilePage = () => {
    var _a, _b, _c;
    const { classes } = useFarmerProfilePageStyles();
    const { userId, invitationId } = useGetFarmerProfileParamsFromUrl();
    const { data: profile, isLoading: isLoadingInfos } = useGetFarmerProfileInfos({ userId, invitationId });
    const { data: farmSeasons, isLoading: isLoadingFarmSeasons } = useGetFarmerProfileFarmSeasons({
        userId,
        invitationId,
    });
    const defaultSeason = ((_a = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[0]) === null || _a === void 0 ? void 0 : _a.cohort) === COHORTS.COHORT_1 ? farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[1] : farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[0];
    const currentFarmSeason = (_b = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.find((fs) => !!fs.id && fs.status !== FARM_SEASON_STATUS.RESULTS_PUBLISHED)) !== null && _b !== void 0 ? _b : defaultSeason;
    const isLoading = isLoadingInfos || isLoadingFarmSeasons;
    if (isLoading)
        return _jsx(FarmerProfilePageSkeleton, {});
    return (_jsxs("div", { className: classes.container, children: [_jsx(FarmerProfileTopNav, {}), _jsxs("div", { className: classes.farmerProfileSection, children: [!!profile && (_jsx(FarmerProfileInfos, { user: profile === null || profile === void 0 ? void 0 : profile.user, farm: profile === null || profile === void 0 ? void 0 : profile.farm, company: profile === null || profile === void 0 ? void 0 : profile.company, farmSeason: currentFarmSeason, assignedContacts: profile === null || profile === void 0 ? void 0 : profile.assigned_to, baselineProductPlan: profile === null || profile === void 0 ? void 0 : profile.baseline_product_plan })), !!((_c = profile === null || profile === void 0 ? void 0 : profile.user) === null || _c === void 0 ? void 0 : _c.subscribed_at) && farmSeasons && (_jsx("div", { className: classes.farmerProfileSeasonsContainer, children: _jsx(FarmerProfileTimeline, { user: profile.user, farmSeasons: farmSeasons }) }))] })] }));
};
