var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, useLazyGetAlarmingDataProcessQuery } from '@services';
import { useAppDispatch } from '@hooks';
import { clearErrorType, ERRORS, setError } from '@/errorSlice';
import { useTranslation } from 'react-i18next';
export const useGenerateAgroChecks = ({ onSuccess, onError, } = {}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [computeAgroChecksMutation, { isLoading: isGeneratingAgroChecks, isSuccess, isError }] = useLazyGetAlarmingDataProcessQuery();
    const generateAgroChecks = (_a) => __awaiter(void 0, [_a], void 0, function* ({ farmSeasonId, processHistory = false, }) {
        try {
            const agroChecks = yield computeAgroChecksMutation({
                farmSeasonId: farmSeasonId,
                processHistory,
            }).unwrap();
            dispatch(api.util.invalidateTags(['AlarmingData']));
            dispatch(clearErrorType(ERRORS.COULD_NOT_GENERATE_AGRO_CHECKS));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            return { success: true, agroChecks };
        }
        catch (_e) {
            dispatch(setError({
                type: ERRORS.COULD_NOT_GENERATE_AGRO_CHECKS,
                message: t('errors.could-not-generate-agro-check'),
            }));
            onError === null || onError === void 0 ? void 0 : onError();
            return { success: false, agroChecks: [] };
        }
    });
    return { generateAgroChecks, isGeneratingAgroChecks, isSuccess, isError };
};
