import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFarmSeasonStatusPanelStyles } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel.style';
import { AUDIT_STATUS, FARM_SEASON_STATUS } from '@/types/farmSeason.types';
import { Timeline } from '@components/Timeline';
import { AccountCreatedStep } from '@features/farmer/profile/timeline/AccountCreatedStep/AccountCreatedStep';
import { SubscribedStep } from '@features/farmer/profile/timeline/SubscribedStep/SubscribedStep';
import { RenewalStep } from '@features/farmer/profile/timeline/RenewalStep/RenewalStep';
import { FarmerDataInputStep } from '@features/farmer/profile/timeline/FarmerDataInputStep/FarmerDataInputStep';
import { AgronomistDataInputStep } from '@features/farmer/profile/timeline/AgronomistDataInputStep/AgronomistDataInputStep';
import { DataInputStep } from '@features/farmer/profile/timeline/DataInputStep/DataInputStep';
import { DataVerificationStep } from '@features/farmer/profile/timeline/DataVerificationStep/DataVerificationStep';
import { ResultsGenerationStep } from '@features/farmer/profile/timeline/ResultsGenerationStep/ResultsGenerationStep';
import { ResultsPublicationStep } from '@features/farmer/profile/timeline/ResultsPublicationStep/ResultsPublicationStep';
import { Banner } from '@components/Banner';
import { IconInfo } from '@components/Icons';
export const FarmSeasonStatusPanel = ({ user, farmSeason, }) => {
    const { t } = useTranslation();
    const { classes } = useFarmSeasonStatusPanelStyles();
    const titleLabel = farmSeason.is_baseline
        ? t('labels.baseline-season-years', { years: farmSeason.harvest_year })
        : t('labels.season-count-years', {
            count: farmSeason.carbon_programme_year,
            years: farmSeason.harvest_year,
        });
    const badgeLabel = farmSeason.audit_status === AUDIT_STATUS.DONE
        ? t('labels.audit-done')
        : farmSeason.audit_status === AUDIT_STATUS.IN_PROGRESS
            ? t('labels.audit-in-progress')
            : farmSeason.status === FARM_SEASON_STATUS.RESULTS_PUBLISHED
                ? t('labels.completed')
                : t('labels.in-progress');
    const isCompleted = farmSeason.status === FARM_SEASON_STATUS.RESULTS_PUBLISHED;
    return (_jsxs(Card, { className: classes.root, children: [_jsxs(Typography, { variant: "h2", className: classes.title, children: [titleLabel, _jsx(Chip, { color: isCompleted ? 'success' : 'info', label: badgeLabel })] }), farmSeason.audit_status === AUDIT_STATUS.IN_PROGRESS && (_jsx(Banner, { sx: { mt: 4 }, Icon: _jsx(Banner.Icon, { icon: _jsx(IconInfo, { sx: { width: 16, color: 'white' } }), variant: "info" }), variant: "info", children: _jsx(Banner.Body, { children: t('farmers.audit.banner.audit-in-progress') }) })), farmSeason.audit_status === AUDIT_STATUS.DONE && (_jsx(Banner, { sx: { mt: 4 }, Icon: _jsx(Banner.Icon, { icon: _jsx(IconInfo, { sx: { width: 16, color: 'white' } }), variant: "info" }), variant: "info", children: _jsx(Banner.Body, { children: t('farmers.audit.banner.audit-done') }) })), _jsx("div", { className: classes.statusesContainer, children: _jsxs(Timeline, { direction: "vertical", children: [_jsx(AccountCreatedStep, { farmSeason: farmSeason, user: user }), _jsx(SubscribedStep, { farmSeason: farmSeason, user: user }), _jsx(RenewalStep, { farmSeason: farmSeason }), _jsx(FarmerDataInputStep, { farmSeason: farmSeason, user: user }), _jsx(AgronomistDataInputStep, { farmSeason: farmSeason, user: user }), _jsx(DataInputStep, { farmSeason: farmSeason, user: user }), _jsx(DataVerificationStep, { farmSeason: farmSeason, user: user }), _jsx(ResultsGenerationStep, { farmSeason: farmSeason, user: user }), _jsx(ResultsPublicationStep, { farmSeason: farmSeason, user: user })] }) })] }));
};
