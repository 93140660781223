var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePermission, useToggle } from '@hooks';
import { useDeactivateUser } from '@features/deactivate-user/useDeactivateUser';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DeactivateUserModal } from '@features/deactivate-user/DeactivateUserModal';
import { IconCross } from '@components/Icons';
export const DeactivateUserButton = ({ userId, isActive, subscribedAt, }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { canDeactivateUser } = usePermission();
    const { open, handleOpen, handleClose } = useToggle();
    const { deactivateUser, isDeactivatingUser } = useDeactivateUser({
        onSuccess: () => enqueueSnackbar(t('snackbar.user-deactivated'), { variant: 'success' }),
        onError: () => enqueueSnackbar(t('errors.could-not-deactivate-user'), { variant: 'error' }),
    });
    const isSubscribed = !!subscribedAt;
    if (!isActive || !canDeactivateUser || !userId || !isSubscribed)
        return _jsx(_Fragment, {});
    const onConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deactivateUser({ userId });
        handleClose();
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "text", startIcon: _jsx(IconCross, { sx: { width: 16 } }), onClick: handleOpen, disabled: isDeactivatingUser, children: t('farmers.deactivate-user.button') }), _jsx(DeactivateUserModal, { open: open, onClose: handleClose, onConfirm: onConfirm, isLoading: isDeactivatingUser })] }));
};
