var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconDash = (_a) => {
    var { color = 'primary', sx } = _a, rest = __rest(_a, ["color", "sx"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", color: color, sx: Object.assign({ fill: 'none' }, sx) }, rest, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 6H8.52941C9.0817 6 9.52941 6.44771 9.52941 7V8.52941C9.52941 9.0817 9.0817 9.52941 8.52941 9.52941H7C6.44772 9.52941 6 9.0817 6 8.52941V7C6 6.44772 6.44771 6 7 6ZM4 7C4 5.34314 5.34315 4 7 4H8.52941C10.1863 4 11.5294 5.34315 11.5294 7V8.52941C11.5294 10.1863 10.1863 11.5294 8.52941 11.5294H7C5.34314 11.5294 4 10.1863 4 8.52941V7ZM7 14.4706H8.52941C9.0817 14.4706 9.52941 14.9183 9.52941 15.4706V17C9.52941 17.5523 9.0817 18 8.52941 18H7C6.44772 18 6 17.5523 6 17V15.4706C6 14.9183 6.44771 14.4706 7 14.4706ZM4 15.4706C4 13.8137 5.34315 12.4706 7 12.4706H8.52941C10.1863 12.4706 11.5294 13.8137 11.5294 15.4706V17C11.5294 18.6569 10.1863 20 8.52941 20H7C5.34314 20 4 18.6569 4 17V15.4706ZM17 6H15.4706C14.9183 6 14.4706 6.44772 14.4706 7V8.52941C14.4706 9.0817 14.9183 9.52941 15.4706 9.52941H17C17.5523 9.52941 18 9.0817 18 8.52941V7C18 6.44771 17.5523 6 17 6ZM15.4706 4C13.8137 4 12.4706 5.34314 12.4706 7V8.52941C12.4706 10.1863 13.8137 11.5294 15.4706 11.5294H17C18.6569 11.5294 20 10.1863 20 8.52941V7C20 5.34315 18.6569 4 17 4H15.4706ZM15.4707 14.4706H17.0001C17.5524 14.4706 18.0001 14.9183 18.0001 15.4706V17C18.0001 17.5523 17.5524 18 17.0001 18H15.4707C14.9184 18 14.4707 17.5523 14.4707 17V15.4706C14.4707 14.9183 14.9184 14.4706 15.4707 14.4706ZM12.4707 15.4706C12.4707 13.8137 13.8138 12.4706 15.4707 12.4706H17.0001C18.657 12.4706 20.0001 13.8137 20.0001 15.4706V17C20.0001 18.6569 18.657 20 17.0001 20H15.4707C13.8138 20 12.4707 18.6569 12.4707 17V15.4706Z", fill: "currentColor" }) })));
};
IconDash.displayName = 'IconDash';
