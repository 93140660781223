import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useGetCohorts } from '@hooks/useGetCohorts';
import { useTranslation } from 'react-i18next';
import { Select } from '@components/Select';
import { Skeleton } from '@mui/material';
export const CohortFilter = ({ values, onChange }) => {
    const { t } = useTranslation();
    const { data: cohorts, isLoading } = useGetCohorts();
    const handleChange = (values) => onChange(values);
    const getOptionLabel = (cohort) => cohort.slug;
    const getOptionValue = (cohort) => cohort.id;
    if (isLoading)
        return _jsx(Skeleton, { width: 92, height: 40, variant: "rounded" });
    if (!cohorts)
        return _jsx(_Fragment, {});
    return (_jsx(Select, { values: values !== null && values !== void 0 ? values : [], onChange: handleChange, options: cohorts, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (cohorts, allAreSelected) => (_jsx(Select.LabelWithPill, { label: t('common.cohort'), selected: cohorts.length, areAllSelected: allAreSelected })), renderOption: (option, props) => (_jsx(Select.CheckboxOption, Object.assign({}, props, { label: `${t(`farmers.cohort.${option.slug}`)} (${option.year})` }))), renderOptionAll: (props) => _jsx(Select.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};
