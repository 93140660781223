import { makeStyles } from 'tss-react/mui';
export const useTimelineStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    verticalTimeline: {
        flexDirection: 'column',
        height: '100%',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    verticalItem: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: theme.spacing(2),
    },
    step: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: 0,
        minWidth: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    verticalStep: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 'auto',
        height: 'auto',
    },
    content: {
        position: 'absolute',
        top: '100%',
        left: 16,
        transform: 'translateX(-50%)',
    },
    verticalContent: {
        position: 'initial',
        transform: 'none',
    },
    node: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center',
        position: 'relative',
    },
    segment: {
        flexGrow: 1,
        height: '1px',
        borderTopStyle: 'dotted',
        borderTopWidth: '3px',
        borderTopColor: theme.palette.grey[400],
        minWidth: 32,
    },
    verticalSegment: {
        borderRadius: theme.shape.borderRadius,
        width: '1px',
        borderTop: 'none',
        borderLeftStyle: 'dotted',
        borderLeftWidth: '2px',
        borderLeftColor: theme.palette.grey[400],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minWidth: '1px',
        minHeight: 32,
    },
    segmentDone: {
        borderTopStyle: 'solid',
        borderLeftStyle: 'solid',
        borderTopColor: theme.palette.success.dark,
        borderLeftColor: theme.palette.success.dark,
    },
    outerCircle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 32,
        height: 32,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'inherit',
    },
    innerCircle: {
        width: 16,
        height: 16,
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'inherit',
    },
    outerCircleNotStarted: {
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[400],
    },
    outerCircleInProgress: {
        backgroundColor: 'white',
        borderColor: theme.palette.success.dark,
    },
    outerCircleDone: {
        backgroundColor: theme.palette.success.dark,
        borderColor: theme.palette.success.dark,
    },
    innerCircleNotStarted: {
        borderColor: 'white',
    },
    innerCircleInProgress: {
        backgroundColor: theme.palette.success.dark,
    },
    label: {
        whiteSpace: 'pre',
    },
    selectedLabel: {
        fontWeight: 'bold',
    },
}));
