var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContextScope, composeEventHandlers } from '@utils';
import { Children, cloneElement, forwardRef, isValidElement, useCallback, } from 'react';
import { useControllableState } from '@hooks';
import { useTimelineStyles } from '@components/Timeline/Timeline.style';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
const TIMELINE_SCOPE_NAME = 'Timeline';
const [createTimelineContext, createTimelineScope] = createContextScope(TIMELINE_SCOPE_NAME);
const [TimelineProvider, useTimelineContext] = createTimelineContext(TIMELINE_SCOPE_NAME);
const Timeline = (_a, ref) => {
    var { __scopeTimeline, selectedStep: selectedStepProp, defaultSelectedStep, onStepSelect, children, className, direction = 'horizontal' } = _a, rest = __rest(_a, ["__scopeTimeline", "selectedStep", "defaultSelectedStep", "onStepSelect", "children", "className", "direction"]);
    const { classes, cx } = useTimelineStyles();
    const [selectedStep, setSelectedStep] = useControllableState({
        prop: selectedStepProp,
        defaultProp: defaultSelectedStep,
        onChange: onStepSelect,
    });
    const steps = Children.toArray(children).map((child) => {
        var _a;
        if (!isValidElement(child)) {
            const childType = isValidElement(child) ? ((_a = child.type) === null || _a === void 0 ? void 0 : _a.displayName) || child.type : typeof child;
            throw new Error(`invalid child type: ${childType}`);
        }
        return child.props.value;
    });
    const firstType = typeof steps[0];
    if (!steps.every((step) => typeof step === firstType)) {
        throw new Error('[Timeline] All step values must be either all `string` or all `number`, but not a mix.');
    }
    return (_jsx(TimelineProvider, { scope: __scopeTimeline, steps: steps, selectedStep: selectedStep, setSelectedStep: setSelectedStep, direction: direction, children: _jsx("div", Object.assign({ ref: ref, className: cx(classes.root, direction === 'vertical' && classes.verticalTimeline, className) }, rest, { children: Children.toArray(children).map((child, index) => cloneElement(child, { index })) })) }));
};
const TimelineComponent = (props, ref) => {
    return Timeline(props, ref);
};
TimelineComponent.displayName = 'Timeline';
const ForwardedTimeline = forwardRef(TimelineComponent);
/* -------------------------------------------------------------------------------------------------
 * TimelineItem
 * -----------------------------------------------------------------------------------------------*/
const ITEM_SCOPE_NAME = 'TimelineItem';
export const TIMELINE_ITEM_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
};
const [TimelineItemProvider, useTimelineItemContext] = createTimelineContext(ITEM_SCOPE_NAME);
const TimelineItem = forwardRef((_a, ref) => {
    var { __scopeTimeline, index = 0, value, status = TIMELINE_ITEM_STATUS.NOT_STARTED, children, className, style } = _a, rest = __rest(_a, ["__scopeTimeline", "index", "value", "status", "children", "className", "style"]);
    const { classes, cx } = useTimelineStyles();
    const { steps, direction } = useTimelineContext(TIMELINE_SCOPE_NAME, __scopeTimeline);
    const count = steps.length;
    const width = index !== count - 1 ? { width: `${100 / (count - 1)}%` } : {};
    return (_jsx(TimelineItemProvider, { scope: __scopeTimeline, value: value, status: status, children: _jsx("div", Object.assign({ ref: ref, className: cx(classes.item, direction === 'vertical' && classes.verticalItem, className), style: direction === 'horizontal' ? Object.assign(Object.assign({}, width), style) : style }, rest, { children: children })) }));
});
TimelineItem.displayName = 'TimelineItem';
/* -------------------------------------------------------------------------------------------------
 * TimelineStep
 * -----------------------------------------------------------------------------------------------*/
const STEP_SCOPE_NAME = 'TimelineStep';
const TimelineStep = forwardRef((_a, ref) => {
    var { __scopeTimeline, onClick, variant = 'text', disableRipple = true, disabled = true, children, className } = _a, rest = __rest(_a, ["__scopeTimeline", "onClick", "variant", "disableRipple", "disabled", "children", "className"]);
    const { classes, cx } = useTimelineStyles();
    const { setSelectedStep, direction } = useTimelineContext(STEP_SCOPE_NAME, __scopeTimeline);
    const { value } = useTimelineItemContext(STEP_SCOPE_NAME, __scopeTimeline);
    const handleClick = useCallback(composeEventHandlers(onClick, () => setSelectedStep(value)), [value]);
    return (_jsx(Button, Object.assign({ ref: ref, disableRipple: disableRipple, variant: variant, className: cx(classes.step, direction === 'vertical' && classes.verticalStep, className), onClick: handleClick, disabled: disabled }, rest, { children: children })));
});
TimelineStep.displayName = 'TimelineStep';
/* -------------------------------------------------------------------------------------------------
 * TimelineNode
 * -----------------------------------------------------------------------------------------------*/
const NODE_SCOPE_NAME = 'TimelineNode';
const TimelineNode = forwardRef((_a, ref) => {
    var { __scopeTimeline, className } = _a, rest = __rest(_a, ["__scopeTimeline", "className"]);
    const { classes, cx } = useTimelineStyles();
    const { status } = useTimelineItemContext(NODE_SCOPE_NAME, __scopeTimeline);
    const mapStatusClasses = {
        [TIMELINE_ITEM_STATUS.NOT_STARTED]: {
            outerCircle: classes.outerCircleNotStarted,
            innerCircle: classes.innerCircleNotStarted,
        },
        [TIMELINE_ITEM_STATUS.IN_PROGRESS]: {
            outerCircle: classes.outerCircleInProgress,
            innerCircle: classes.innerCircleInProgress,
        },
        [TIMELINE_ITEM_STATUS.DONE]: {
            outerCircle: classes.outerCircleDone,
            innerCircle: '',
        },
    };
    return (_jsx(Box, Object.assign({ className: cx(classes.node, className), ref: ref }, rest, { children: _jsx("div", { className: cx(classes.outerCircle, cx(mapStatusClasses[status].outerCircle)), children: status === TIMELINE_ITEM_STATUS.DONE ? (_jsx(CheckIcon, { sx: { color: 'white' } })) : (_jsx("div", { className: cx(classes.innerCircle, cx(mapStatusClasses[status].innerCircle)) })) }) })));
});
TimelineNode.displayName = 'TimelineNode';
/* -------------------------------------------------------------------------------------------------
 * TimelineSegment
 * -----------------------------------------------------------------------------------------------*/
const SEGMENT_SCOPE_NAME = 'TimelineSegment';
const TimelineSegment = forwardRef((_a, ref) => {
    var { __scopeTimeline, className } = _a, rest = __rest(_a, ["__scopeTimeline", "className"]);
    const { classes, cx } = useTimelineStyles();
    const { direction } = useTimelineContext(SEGMENT_SCOPE_NAME, __scopeTimeline);
    const { status } = useTimelineItemContext(SEGMENT_SCOPE_NAME, __scopeTimeline);
    return (_jsx("div", Object.assign({ ref: ref, className: cx(classes.segment, direction === 'vertical' && classes.verticalSegment, status === TIMELINE_ITEM_STATUS.DONE && classes.segmentDone, className) }, rest)));
});
TimelineSegment.displayName = 'TimelineSegment';
/* -------------------------------------------------------------------------------------------------
 * TimelineContent
 * -----------------------------------------------------------------------------------------------*/
const CONTENT_SCOPE_NAME = 'TimelineContent';
const TimelineContent = forwardRef((_a, ref) => {
    var { __scopeTimeline, children, className } = _a, rest = __rest(_a, ["__scopeTimeline", "children", "className"]);
    const { classes, cx } = useTimelineStyles();
    const { direction } = useTimelineContext(CONTENT_SCOPE_NAME, __scopeTimeline);
    return (_jsx("div", Object.assign({ ref: ref, className: cx(classes.content, direction === 'vertical' && classes.verticalContent, className) }, rest, { children: children })));
});
TimelineContent.displayName = 'TimelineContent';
/* -------------------------------------------------------------------------------------------------
 * TimelineLabel
 * -----------------------------------------------------------------------------------------------*/
const LABEL_SCOPE_NAME = 'TimelineLabel';
const TimelineLabel = forwardRef((_a, ref) => {
    var { __scopeTimeline, children, variant = 'body1', className } = _a, rest = __rest(_a, ["__scopeTimeline", "children", "variant", "className"]);
    const { classes, cx } = useTimelineStyles();
    const { selectedStep } = useTimelineContext(LABEL_SCOPE_NAME, __scopeTimeline);
    const { value } = useTimelineItemContext(LABEL_SCOPE_NAME, __scopeTimeline);
    return (_jsx(Typography, Object.assign({ ref: ref, variant: variant, className: cx(classes.label, selectedStep === value && classes.selectedLabel, className) }, rest, { children: children })));
});
TimelineLabel.displayName = 'TimelineLabel';
export { createTimelineScope, ForwardedTimeline as Timeline, TimelineItem, TimelineStep, TimelineNode, TimelineSegment, TimelineContent, TimelineLabel, };
