var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { usePermission } from '@hooks';
import { CARBON_MODEL } from '@/types/farmSeason.types';
export const SeeResultsButton = (_a) => {
    var { children, farmSeasonId, carbonModel, resultsPublishedAt } = _a, props = __rest(_a, ["children", "farmSeasonId", "carbonModel", "resultsPublishedAt"]);
    const { canSeeResults } = usePermission();
    const hasPublishedResults = !!resultsPublishedAt;
    const path = `${process.env.FARMER_PORTAL_URL_V3}/${farmSeasonId}/results`;
    return canSeeResults && hasPublishedResults && carbonModel === CARBON_MODEL.DNDC ? (_jsx(Button, Object.assign({ href: path, target: "_blank", rel: "noreferrer" }, props, { children: children }))) : (_jsx(_Fragment, {}));
};
