import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle } from '@components/Icons';
import { formatDate } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
export const SubscribedStep = ({ farmSeason, user }) => {
    var _a;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    if (!user)
        return _jsx(_Fragment, {});
    return (_jsx(FarmerProfileTimelineStep, { show: farmSeason.is_baseline, icon: _jsx(IconCheckCircle, { color: "success" }), label: t('statuses.timeline.subscribed'), statusText: formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', user.subscribed_at), isStepCompleted: !!user.subscribed_at, isPreviousStepCompleted: true }));
};
