import { api } from './api.service';
export const farmSeasonProductPlanApi = api.injectEndpoints({
    endpoints: (builder) => ({
        toggleDeferredPayment: builder.mutation({
            query: ({ farmSeasonProductPlanId, status }) => ({
                url: `/private/farm-season-product-plan/${farmSeasonProductPlanId}/deferred-payment`,
                method: 'POST',
                body: {
                    status,
                },
            }),
        }),
    }),
});
export const { useToggleDeferredPaymentMutation } = farmSeasonProductPlanApi;
