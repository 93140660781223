import { createSlice } from '@reduxjs/toolkit';
export const ERRORS = {
    COULD_NOT_VERIFY_ENCODING: 'COULD_NOT_VERIFY_ENCODING',
    COULD_NOT_GENERATE_RESULTS: 'COULD_NOT_GENERATE_RESULTS',
    AGRO_CHECKS_UNRESOLVED: 'AGRO_CHECKS_UNRESOLVED',
    COULD_NOT_GENERATE_AGRO_CHECKS: 'COULD_NOT_GENERATE_AGRO_CHECKS',
    COULD_NOT_CONFIRM_AGRO_CHECKS: 'COULD_NOT_CONFIRM_AGRO_CHECKS',
    COULD_NOT_POST_AGRO_CHECK_COMMENT: 'COULD_NOT_POST_AGRO_CHECK_COMMENT',
    COULD_NOT_UPDATE_AGRO_CHECK_COMMENT: 'COULD_NOT_UPDATE_AGRO_CHECK_COMMENT',
    COULD_NOT_DELETE_AGRO_CHECK_COMMENT: 'COULD_NOT_DELETE_AGRO_CHECK_COMMENT',
    COULD_NOT_DELETE_FARMER: 'COULD_NOT_DELETE_FARMER',
    COULD_NOT_DEACTIVATE_USER: 'COULD_NOT_DEACTIVATE_USER',
};
const initialState = {
    errors: [],
};
const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.errors = [...state.errors, action.payload];
        },
        clearErrorType: (state, action) => {
            state.errors = state.errors.filter((error) => error.type !== action.payload);
        },
        clearError: (state) => {
            state.errors = [];
        },
    },
});
export const selectError = (state) => state.error.errors;
export const { setError, clearErrorType, clearError } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
