import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { useDeferredPaymentBannerStyles } from './DeferredPaymentBanner.style';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { DeferredPaymentSwitch } from '@features/deferredPayment/DeferredPaymentSwitch';
import { usePermission } from '@hooks';
export const DeferredPaymentBanner = ({ subscribedAt, isPlanActive, isDeferredPaymentActive, farmSeasonProductPlanId, }) => {
    const { classes } = useDeferredPaymentBannerStyles();
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(isDeferredPaymentActive);
    const { canApplyDeferredPayment, canApplyDeferredPaymentAssignedFarmers } = usePermission();
    const hasPermission = canApplyDeferredPayment || canApplyDeferredPaymentAssignedFarmers;
    const hasSubscribed = !!subscribedAt;
    const show = hasPermission && (isDeferredPaymentActive || !isPlanActive || !hasSubscribed);
    const displayToggle = hasPermission && !isPlanActive && !hasSubscribed;
    const onToggle = useCallback(() => setIsChecked((prev) => !prev), []);
    return show ? (_jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.wrapper, children: [_jsx(Box, { className: classes.box, children: _jsx(InfoOutlined, { className: classes.icon }) }), _jsxs(Typography, { sx: { px: '16px' }, children: [isDeferredPaymentActive
                                ? t('farmers.deferred-payment.description.active.first')
                                : isChecked
                                    ? t('farmers.deferred-payment.description.on.first')
                                    : t('farmers.deferred-payment.description.off.first'), _jsx("span", { style: { fontWeight: 'bold' }, children: isDeferredPaymentActive
                                    ? t('farmers.deferred-payment.description.active.second')
                                    : isChecked
                                        ? t('farmers.deferred-payment.description.on.second')
                                        : t('farmers.deferred-payment.description.off.second') }), isChecked && !isDeferredPaymentActive
                                ? t('farmers.deferred-payment.description.on.third')
                                : !isDeferredPaymentActive
                                    ? t('farmers.deferred-payment.description.off.third')
                                    : ''] })] }), displayToggle && (_jsx(DeferredPaymentSwitch, { isChecked: isChecked, onToggle: onToggle, farmSeasonProductPlanId: farmSeasonProductPlanId }))] })) : (_jsx(_Fragment, {}));
};
