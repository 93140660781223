import { jsx as _jsx } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconDollar } from '@components/Icons';
import { formatDate } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
export const RenewalStep = ({ farmSeason }) => {
    var _a;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    const isStepCompleted = !!farmSeason.paid_at;
    const statusText = farmSeason.paid_at
        ? formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', farmSeason.paid_at)
        : t('statuses.farm-season.PENDING_RENEWAL');
    return (_jsx(FarmerProfileTimelineStep, { show: !farmSeason.is_baseline, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: "success" }) : _jsx(IconDollar, {}), label: t('statuses.timeline.renewal'), statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: true }));
};
