import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconDash } from '@components/Icons';
import { useTranslation } from 'react-i18next';
import { CARBON_MODEL } from '@/types/farmSeason.types';
import { formatDate } from '@utils';
import { useAuth } from '@hooks';
export const FarmerDataInputStep = ({ farmSeason, user, }) => {
    var _a;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    if (!user)
        return _jsx(_Fragment, {});
    const shouldShowStep = farmSeason.carbon_model === CARBON_MODEL.CFT;
    const isStepCompleted = !!farmSeason.installation_validated_at;
    const isPreviousStepCompleted = farmSeason.is_baseline ? !!user.subscribed_at : !!farmSeason.paid_at;
    const statusText = !isPreviousStepCompleted
        ? null
        : !farmSeason.self_encoding_started_at
            ? t('labels.not-started')
            : isStepCompleted
                ? formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', farmSeason.installation_validated_at)
                : t('labels.in-progress');
    const iconColor = isStepCompleted
        ? 'success'
        : isPreviousStepCompleted
            ? 'primary'
            : 'disabled';
    return (_jsx(FarmerProfileTimelineStep, { show: shouldShowStep, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: iconColor }) : _jsx(IconDash, { color: iconColor }), label: t('statuses.timeline.farmer-data-input'), statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: isPreviousStepCompleted }));
};
