import i18n from '@/i18n';
import { api } from '@services/api.service';
export const INVITATION_VALIDATION = {
    FARMER_ALREADY_INVITED_BY_ANOTHER_PARTNER: 'FARMER_ALREADY_INVITED_BY_ANOTHER_PARTNER',
    FARMER_ALREADY_SUBSCRIBED: 'FARMER_ALREADY_SUBSCRIBED',
};
export const invitationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserByInvitationId: builder.query({
            query: (data) => ({
                url: `/private/invitations/${data.invitationId}/user`,
            }),
        }),
        getPartnerInvitationById: builder.query({
            query: (data) => ({
                url: `/private/invitations/${data.invitationId}/partner`,
            }),
        }),
        getAllFarmersPendingInvitations: builder.query({
            query: () => ({
                url: '/private/invitations/farmers',
                method: 'GET',
            }),
            providesTags: ['Farmers'],
        }),
        getAllMembersPendingInvitations: builder.query({
            query: () => ({
                url: '/private/invitations/members',
                method: 'GET',
            }),
            providesTags: ['Members'],
        }),
        inviteFarmers: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/farmer/custom?languageId=${data.languageId}&lang=${data.languageCode}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Farmers'],
        }),
        resendFarmerInvites: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/farmer/resend/custom?languageId=${data.languageId}&lang=${data.languageCode}`,
                method: 'PUT',
                body: data.body.ids,
            }),
            invalidatesTags: ['Farmers'],
        }),
        inviteMembers: builder.mutation({
            query: (data) => ({
                url: `/private/invitations?lang=${i18n.language}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Members'],
        }),
        resendMembers: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/resend?lang=${i18n.language}`,
                method: 'PUT',
                body: data.ids,
            }),
            invalidatesTags: ['Members'],
        }),
        deleteMemberInvitations: builder.mutation({
            query: (data) => ({
                url: `/private/invitations`,
                method: 'DELETE',
                body: data.ids,
            }),
            invalidatesTags: ['Members'],
        }),
        checkIfPartnerCanInviteFarmer: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/partner/can-invite/farmers`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
export const { useGetUserByInvitationIdQuery, useGetPartnerInvitationByIdQuery, useGetAllFarmersPendingInvitationsQuery, useGetAllMembersPendingInvitationsQuery, useInviteFarmersMutation, useResendFarmerInvitesMutation, useInviteMembersMutation, useResendMembersMutation, useDeleteMemberInvitationsMutation, useCheckIfPartnerCanInviteFarmerMutation, } = invitationApi;
