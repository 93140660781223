import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconChevronRight, IconUserEdit } from '@components/Icons';
import { formatDate } from '@utils';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks';
import { CARBON_MODEL } from '@/types/farmSeason.types';
import { VisitFarmButton } from '@features/farmer/profile/visitFarm/VisitFarmButton';
import { useFarmSeasonStatusPanelStyles } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel.style';
export const AgronomistDataInputStep = ({ farmSeason, user, }) => {
    var _a;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    const { classes } = useFarmSeasonStatusPanelStyles();
    if (!user || !farmSeason.id)
        return _jsx(_Fragment, {});
    const shouldShowStep = farmSeason.carbon_model === CARBON_MODEL.CFT;
    const isStepCompleted = !!farmSeason.summary_validated_at;
    const isPreviousStepCompleted = !!farmSeason.installation_validated_at;
    const statusText = !isPreviousStepCompleted
        ? null
        : isStepCompleted
            ? formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', farmSeason.summary_validated_at)
            : t('labels.in-progress');
    const iconColor = isStepCompleted
        ? 'success'
        : isPreviousStepCompleted
            ? 'primary'
            : 'disabled';
    return (_jsx(FarmerProfileTimelineStep, { show: shouldShowStep, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: iconColor }) : _jsx(IconUserEdit, { color: iconColor }), label: t('statuses.timeline.agronomist-data-input'), statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: isPreviousStepCompleted, extra: _jsx("div", { className: classes.statusActions, children: _jsx(VisitFarmButton, { variant: "text", sx: { textDecoration: 'underline', p: 0, '&:hover': { background: 'none' } }, endIcon: _jsx(IconChevronRight, { sx: { width: 12 } }), isSubscribed: !!user.subscribed_at, userId: user.id, farmSeasonId: farmSeason.id, carbonModel: farmSeason.carbon_model, children: t('buttons.access-encoding') }) }) }));
};
