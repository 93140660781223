var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconHourglass = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", color: color }, rest, { children: _jsx("path", { d: "M9.33301 14C10.103 14 10.733 13.37 10.733 12.6L10.726 10.374C10.726 10.003 10.579 9.653 10.32 9.387L7.93301 7L10.32 4.599C10.579 4.34 10.726 3.983 10.726 3.612L10.733 1.4C10.733 0.63 10.103 0 9.33301 0H3.73301C2.96301 0 2.33301 0.63 2.33301 1.4V3.612C2.33301 3.983 2.48001 4.34 2.73901 4.606L5.13301 7L2.74601 9.38C2.48001 9.646 2.33301 10.003 2.33301 10.374V12.6C2.33301 13.37 2.96301 14 3.73301 14H9.33301ZM3.73301 3.563V2.1C3.73301 1.715 4.04801 1.4 4.43301 1.4H8.63301C9.01801 1.4 9.33301 1.715 9.33301 2.1V3.563C9.33301 3.752 9.25601 3.927 9.13001 4.06L6.53301 6.65L3.93601 4.053C3.81001 3.927 3.73301 3.745 3.73301 3.563Z", fill: "currentColor" }) })));
};
IconHourglass.displayName = 'IconHourglass';
