var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import { useToggleDeferredPayment } from '@features/deferredPayment/useToggleDeferredPayment';
import { useTranslation } from 'react-i18next';
import { useDeferredPaymentBannerStyles } from '@features/deferredPayment/DeferredPaymentBanner.style';
export const DeferredPaymentSwitch = ({ isChecked, onToggle, farmSeasonProductPlanId, }) => {
    const { t } = useTranslation();
    const { classes } = useDeferredPaymentBannerStyles();
    const { toggleDeferredPayment, isLoading } = useToggleDeferredPayment();
    const handleSwitch = () => __awaiter(void 0, void 0, void 0, function* () {
        yield toggleDeferredPayment({ farmSeasonProductPlanId, status: isChecked ? 'off' : 'on' });
        onToggle();
    });
    return (_jsx(FormControlLabel, { disabled: isLoading, control: _jsx(Switch, { checked: isChecked, className: classes.switch, onChange: handleSwitch }), label: _jsx(Typography, { fontWeight: "bold", sx: { mr: 1 }, children: isChecked ? t('farmers.deferred-payment.active') : t('farmers.deferred-payment.inactive') }), labelPlacement: "start" }));
};
