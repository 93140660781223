import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useChangePartnerAssignedContacts } from '@features/assignment/useChangePartnerAssignedContacts';
import { ContactsEditForm } from '@features/assignment/ContactsEditForm/ContactEditForm';
export const PartnerContactsEditForm = ({ assignedContacts, assignableContacts, profileInvitationId, onCancel, onSave, }) => {
    const [selectedContacts, setSelectedContacts] = useState(assignedContacts || []);
    const { changeAssignedContacts, isLoading } = useChangePartnerAssignedContacts({
        selectedContacts,
        assignedContacts,
        invitationId: profileInvitationId,
        onSuccess: onSave,
    });
    return (_jsx(ContactsEditForm, { assignedContacts: selectedContacts, onContactChange: setSelectedContacts, assignableContacts: assignableContacts, onCancel: onCancel, onSubmit: changeAssignedContacts, isLoading: isLoading }));
};
