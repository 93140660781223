import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AvatarGroup, Tooltip, Typography } from '@mui/material';
import { Avatar } from '@components/Avatar';
import { getAvatarInitials } from '@utils/string';
import { useContactListStyles } from '@features/assignment/ContactList/ContactList.style';
export const ContactList = ({ contacts, max = 4 }) => {
    var _a;
    const { t } = useTranslation();
    const hasAssignedContact = contacts && contacts.length > 0;
    const { classes } = useContactListStyles();
    if (!hasAssignedContact)
        return _jsx(Typography, { variant: "body1", children: t('farmers.profile.about.no-contact') });
    const extra = contacts.length - max;
    if (contacts.length > 2)
        return (_jsxs(AvatarGroup, { slotProps: { additionalAvatar: { className: classes.avatar } }, spacing: "medium", sx: { gap: 1, justifyContent: 'flex-end' }, children: [(_a = contacts.slice(0, max)) === null || _a === void 0 ? void 0 : _a.map((contact) => (_jsx(Tooltip, { arrow: true, title: `${contact.first_name} ${contact.last_name}`, children: _jsx(Avatar, { className: classes.avatar, children: getAvatarInitials(contact.first_name, contact.last_name, contact.email) }) }, contact.id))), extra > 0 && (_jsx(Tooltip, { arrow: true, sx: { whiteSpace: 'pre-wrap' }, title: _jsx(_Fragment, { children: contacts === null || contacts === void 0 ? void 0 : contacts.slice(4, contacts.length).map((contact) => (_jsx(Typography, { variant: "body2", sx: { color: 'white' }, children: `${contact === null || contact === void 0 ? void 0 : contact.first_name} ${contact === null || contact === void 0 ? void 0 : contact.last_name}` }, contact.id))) }), children: _jsxs(Avatar, { className: classes.avatar, children: ["+ ", (contacts === null || contacts === void 0 ? void 0 : contacts.length) - 4] }) }))] }));
    return (_jsx("div", { className: classes.list, children: contacts.map((contact) => (_jsxs("div", { className: classes.contact, children: [_jsx(Avatar, { className: classes.avatar, children: getAvatarInitials(contact.first_name, contact.last_name, contact.email) }), _jsxs(Typography, { variant: "body2", children: [contact.first_name, " ", contact.last_name] })] }, Number(contact.id)))) }));
};
