import { usePermission } from '@hooks/usePermission';
import { generateRoute } from '@router/routes.utils';
import { ROUTES } from '@router/routes';
export const useAlarmingDataAccess = ({ id, farmSeasonId }) => {
    const { canSeeAlarmingData, canSeeAssignedAlarmingData, canSeeAssignedPartnerAlarmingData, canSeePartnerAlarmingData, } = usePermission();
    const canAccessAlarmingDataPage = canSeeAlarmingData ||
        canSeeAssignedAlarmingData ||
        canSeeAssignedPartnerAlarmingData ||
        canSeePartnerAlarmingData;
    const alarmingDataPagePath = generateRoute(ROUTES.ALARMING_DATA, {
        farmSeasonId: farmSeasonId,
        id,
    });
    return { canAccessAlarmingDataPage, alarmingDataPagePath };
};
