var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FarmerProfileTimelineStep } from '@features/farmer/profile/timeline/FarmerProfileTimelineStep';
import { IconCheckCircle, IconChevronRight, IconSearch, IconWarning } from '@components/Icons';
import { formatDate } from '@utils';
import { Trans, useTranslation } from 'react-i18next';
import { ROLE_GROUP, useAuth, useUserRoleGroup } from '@hooks';
import { AlarmingDataButton } from '@features/alarmingData/AlarmingDataButton/AlarmingDataButton';
import { VerifyEncodingButton } from '@features/verify-encoding/VerifyEncodingButton/VerifyEncodingButton';
import { useFarmSeasonStatusPanelStyles } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel.style';
import { CircularProgress, Typography } from '@mui/material';
import { Banner } from '@components/Banner';
import { useDataVerificationStep } from './useDataVerificationStep';
import { useSelector } from 'react-redux';
import { ERRORS, selectError } from '@/errorSlice';
import { useIsFarmSeasonEditable } from '@features/farmer/profile/useIsFarmSeasonEditable';
export const DataVerificationStep = ({ farmSeason, user, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { user: authUser } = useAuth();
    const { classes } = useFarmSeasonStatusPanelStyles();
    const errors = useSelector(selectError);
    const { roleGroup } = useUserRoleGroup();
    const { isEditable } = useIsFarmSeasonEditable({ farmSeason, user });
    const { agroChecks, generateAgroChecks, isGeneratingAgroChecks, isLoadingAgroChecks } = useDataVerificationStep({
        farmSeason,
    });
    if (!user)
        return _jsx(_Fragment, {});
    const resolvedAgroChecks = agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.filter((data) => data.resolved_at);
    const hasResolvedAllAgroChecks = (resolvedAgroChecks === null || resolvedAgroChecks === void 0 ? void 0 : resolvedAgroChecks.length) === (agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.length);
    const isStepCompleted = !!farmSeason.encoding_verified_at;
    const isPreviousStepCompleted = !!farmSeason.summary_validated_at;
    const statusText = !isPreviousStepCompleted
        ? null
        : isStepCompleted
            ? formatDate((_a = authUser === null || authUser === void 0 ? void 0 : authUser.language.iso_code) !== null && _a !== void 0 ? _a : 'en', farmSeason.encoding_verified_at)
            : !hasResolvedAllAgroChecks
                ? t('labels.to-be-resolved')
                : t('labels.to-be-confirmed');
    const iconColor = isStepCompleted
        ? 'success'
        : isPreviousStepCompleted
            ? 'primary'
            : 'disabled';
    const onRetry = () => __awaiter(void 0, void 0, void 0, function* () { return generateAgroChecks({ farmSeasonId: farmSeason.id, processHistory: farmSeason.is_baseline }); });
    const generateAggroCheckError = errors.find((error) => error.type === ERRORS.COULD_NOT_GENERATE_AGRO_CHECKS);
    const unresolvedAggroCheckError = errors.find((error) => error.type === ERRORS.AGRO_CHECKS_UNRESOLVED);
    return (_jsx(FarmerProfileTimelineStep, { show: true, icon: isStepCompleted ? _jsx(IconCheckCircle, { color: iconColor }) : _jsx(IconSearch, { color: iconColor }), label: t('statuses.timeline.data-verification'), author: farmSeason.encoding_verified_by, statusText: statusText, isStepCompleted: isStepCompleted, isPreviousStepCompleted: isPreviousStepCompleted, extra: isGeneratingAgroChecks ? (_jsx("div", { className: classes.statusActions, children: _jsxs("div", { className: classes.agroChecksLoadingContainer, children: [_jsx(Typography, { variant: "body2", children: t('farmers.agro-checks.is-processing') }), _jsx(CircularProgress, { size: 24 })] }) })) : (_jsxs("div", { className: classes.statusActions, children: [!!generateAggroCheckError && (_jsx(Banner, { Icon: _jsx(Banner.Icon, { variant: "error", icon: _jsx(IconWarning, { sx: { color: 'white' } }) }), variant: "error", Action: _jsx(Banner.Action, { disabled: isGeneratingAgroChecks, onClick: onRetry, children: isGeneratingAgroChecks ? _jsx(CircularProgress, { size: 16 }) : t('constants.retry') }), children: _jsx(Banner.Body, { sx: { whiteSpace: 'pre-wrap' }, children: _jsx(Trans, { components: { b: _jsx("strong", { style: { fontWeight: 'bold' } }) }, i18nKey: "errors.agro-checks-generation-error" }) }) })), !!unresolvedAggroCheckError && (_jsx(Banner, { Icon: _jsx(Banner.Icon, { variant: "error", icon: _jsx(IconWarning, { sx: { color: 'white' } }) }), variant: "error", children: _jsx(Banner.Body, { sx: { whiteSpace: 'pre-wrap' }, children: _jsx(Trans, { components: { b: _jsx("strong", { style: { fontWeight: 'bold' } }) }, i18nKey: "errors.agro-checks-unresolved" }) }) })), _jsx(AlarmingDataButton, { sx: { minWidth: 120 }, variant: farmSeason.encoding_verified_at || hasResolvedAllAgroChecks ? 'outlined' : 'contained', endIcon: isLoadingAgroChecks ? (_jsx(_Fragment, {})) : (_jsx(IconChevronRight, { sx: {
                            color: farmSeason.encoding_verified_at ? 'primary.main' : 'white',
                            width: 12,
                        } })), userOrInvitationId: roleGroup === ROLE_GROUP.PARTNER ? user.invitation_id : user.id, farmSeasonId: farmSeason.id, children: isLoadingAgroChecks ? (_jsx(CircularProgress, { size: 16, sx: { color: hasResolvedAllAgroChecks ? 'primary.main' : 'white' } })) : (t('alarming-data.buttons.see-agro-checks', {
                        resolved: (_b = resolvedAgroChecks === null || resolvedAgroChecks === void 0 ? void 0 : resolvedAgroChecks.length) !== null && _b !== void 0 ? _b : 0,
                        total: (_c = agroChecks === null || agroChecks === void 0 ? void 0 : agroChecks.length) !== null && _c !== void 0 ? _c : 0,
                    })) }), isEditable && (_jsx(VerifyEncodingButton, { farmSeasonId: farmSeason.id, farmId: farmSeason.farm_id, encodingValidatedAt: farmSeason.summary_validated_at, carbonModel: farmSeason.carbon_model, isBaseline: farmSeason.is_baseline, resultsCalculationStatus: farmSeason.results_calculation_status, variant: farmSeason.encoding_verified_at ? 'outlined' : 'contained', children: farmSeason.results_published_at && !farmSeason.results_unpublished_at
                        ? t('buttons.confirm-encoding-after-publication')
                        : t('buttons.confirm-encoding') }))] })) }));
};
