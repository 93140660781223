var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useGenerateResultsV2Mutation, useGenerateResultsV3Mutation } from '@services/results.api';
import { CARBON_MODEL } from '@/types/farmSeason.types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks';
import { clearErrorType, ERRORS } from '@/errorSlice';
export const useGenerateResults = ({ onSuccess, onError } = {}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [generateResultsV2, { isLoading: isGenerateResultsV2Loading, isSuccess: isSuccessV2, isError: isErrorV2 }] = useGenerateResultsV2Mutation();
    const [generateResultsV3, { isLoading: isGenerateResultsV3Loading, isSuccess: isSuccessV3, isError: isErrorV3 }] = useGenerateResultsV3Mutation();
    const generateResults = (_a) => __awaiter(void 0, [_a], void 0, function* ({ farmSeasonId, farmId, carbonModel, }) {
        try {
            carbonModel === CARBON_MODEL.DNDC
                ? yield generateResultsV3({ farmSeasonId: Number(farmSeasonId) }).unwrap()
                : yield generateResultsV2({ farmSeasonId: Number(farmSeasonId), farmId: Number(farmId) }).unwrap();
            dispatch(clearErrorType(ERRORS.COULD_NOT_GENERATE_RESULTS));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (_e) {
            dispatch({ type: ERRORS.COULD_NOT_GENERATE_RESULTS, message: t('errors.could-not-generate-results') });
            onError === null || onError === void 0 ? void 0 : onError();
        }
    });
    const isGeneratingResults = isGenerateResultsV2Loading || isGenerateResultsV3Loading;
    const isSuccess = isSuccessV2 || isSuccessV3;
    const isError = isErrorV2 || isErrorV3;
    return { generateResults, isGeneratingResults, isSuccess, isError };
};
