export const parseStorageObject = (key) => localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : undefined;
export const deleteFromStorage = (key) => localStorage.removeItem(key);
export const reconcileStorageWithApiValues = (storageKey, apiValues, getValue, storeValues, deleteValuesFromStorage) => {
    if (!apiValues.length)
        return [];
    const valuesFromStorage = parseStorageObject(storageKey);
    if (!valuesFromStorage)
        return [];
    const apiValuesMapped = apiValues.flatMap((item) => {
        const value = getValue(item);
        return Array.isArray(value) ? value : [value];
    });
    const validValuesFromStorage = valuesFromStorage.filter((value) => apiValuesMapped.includes(value));
    const validValues = apiValues.filter((item) => {
        const value = getValue(item);
        const values = Array.isArray(value) ? value : [value];
        return values.some((v) => validValuesFromStorage.includes(v));
    });
    if (validValues.length === apiValues.length)
        return validValues;
    deleteValuesFromStorage();
    if (validValues.length)
        storeValues(validValues);
    return validValues;
};
