import { makeStyles } from 'tss-react/mui';
export const useTabsStyles = makeStyles()((theme) => {
    const MARGIN_TOP_PANEL_FROM_TABS = theme.spacing(4);
    return {
        tabs: {
            border: 'none',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
        },
        tabsList: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
            background: theme.palette.grey[200],
            padding: theme.spacing(2),
        },
        tab: {},
        selectedTab: {
            background: theme.palette.background.paper,
            '&:hover': {
                background: theme.palette.background.paper,
            },
        },
        tabPanel: {
            marginTop: MARGIN_TOP_PANEL_FROM_TABS,
        },
        tabIndicator: {
            content: '""',
            position: 'absolute',
            bottom: `-${MARGIN_TOP_PANEL_FROM_TABS}`,
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: `10px solid ${theme.palette.grey[50]}`,
        },
    };
});
