import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileInfosStyle = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
        },
        actionsContainer: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            top: theme.spacing(6),
            right: theme.spacing(6),
        },
        personalInfosContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        phoneEmailContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
        },
        emailPhone: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        farmerProfessionalInfosContainer: {
            display: 'grid',
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
            marginTop: theme.spacing(10),
        },
        farmInfos: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.spacing(2),
        },
        companyInfos: {
            display: 'flex',
            justifyContent: 'center',
            gap: theme.spacing(2),
        },
        contactsInfos: {
            display: 'flex',
            justifyContent: 'flex-start',
            gap: theme.spacing(2),
        },
        contact: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
    };
});
