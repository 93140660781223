var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { BoxedIcon } from '@components/BoxedIcon';
import { Box, Button, Typography } from '@mui/material';
import { IconCross } from '@components/Icons';
const Banner = (_a) => {
    var { variant = 'neutral', Icon, Action, Close, children, sx } = _a, rest = __rest(_a, ["variant", "Icon", "Action", "Close", "children", "sx"]);
    const variantColor = {
        success: 'success.main',
        warning: 'warning.main',
        error: 'error.main',
        info: 'primary.main',
        neutral: 'grey.400',
    };
    return (_jsxs(Box, Object.assign({ sx: Object.assign({ display: 'flex', alignItems: 'center', gap: 4, paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 4, borderWidth: '1px', borderStyle: 'solid', borderRadius: 1, borderColor: variantColor[variant] }, sx) }, rest, { children: [Icon, " ", children, _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 2, marginLeft: 'auto' }, children: [Action, " ", Close] })] })));
};
export const BannerIcon = (_a) => {
    var { variant, icon, sx } = _a, rest = __rest(_a, ["variant", "icon", "sx"]);
    return (_jsx(BoxedIcon, Object.assign({ sx: Object.assign({ height: 32, width: 32, minWidth: 32, minHeight: 32 }, sx), variant: variant, icon: icon }, rest)));
};
export const BannerBody = (_a) => {
    var { children, variant = 'body1' } = _a, rest = __rest(_a, ["children", "variant"]);
    return (_jsx(Typography, Object.assign({ variant: variant }, rest, { children: children })));
};
export const BannerAction = (_a) => {
    var { children, onClick, sx } = _a, rest = __rest(_a, ["children", "onClick", "sx"]);
    return (_jsx(Button, Object.assign({ variant: "text", sx: Object.assign({ marginLeft: 'auto', textDecoration: 'underline', fontSize: '14px' }, sx), onClick: onClick }, rest, { children: children })));
};
export const BannerClose = (_a) => {
    var { onClick, sx } = _a, rest = __rest(_a, ["onClick", "sx"]);
    return (_jsx(Button, Object.assign({ variant: "text", sx: Object.assign({ marginLeft: 'auto', padding: 2, minWidth: 0, minHeight: 0, width: 32, height: 32, borderRadius: '50%' }, sx), onClick: onClick }, rest, { children: _jsx(IconCross, { sx: { width: 14 } }) })));
};
Banner.Icon = BannerIcon;
Banner.Body = BannerBody;
Banner.Action = BannerAction;
Banner.Close = BannerClose;
export { Banner };
