import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAssignedContactsFormData } from '@features/assignment/useAssignedContactsFormData';
import { usePermission, useToggle } from '@hooks';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { IconEdit, IconPerson } from '@components/Icons';
import { useTranslation } from 'react-i18next';
import { useFarmerProfileContactsStyle } from '@features/farmer/profile/components/FarmerProfileContacts/FarmerProfileContacts.style';
import { useGetFarmerProfileParamsFromUrl } from '@features/farmer/profile/hooks/useGetFarmerProfileParamsFromUrl';
import { ContactList } from '@features/assignment/ContactList/ContactList';
import { ContactForm } from '@features/assignment/ContactForm/ContactForm';
export const FarmerProfileContacts = ({ assignedContacts, userId, farmId, }) => {
    const { t } = useTranslation();
    const { classes } = useFarmerProfileContactsStyle();
    const { invitationId } = useGetFarmerProfileParamsFromUrl();
    const { assignableContacts, isLoading } = useAssignedContactsFormData({ farmerId: invitationId !== null && invitationId !== void 0 ? invitationId : userId });
    const { open: showEditContactForm, handleOpen: handleShowEditContactForm, handleClose: handleHideEditContactForm, } = useToggle();
    const { canAssignAssociateToFarmer } = usePermission();
    const displayAssignContactButton = canAssignAssociateToFarmer ? !!userId && !!farmId : !!userId;
    if (isLoading || !assignableContacts)
        return (_jsxs("div", { className: classes.root, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 2 }, children: [_jsx(IconPerson, {}), _jsx(Typography, { variant: "body1", sx: { textDecoration: 'underline', fontWeight: 'bold' }, children: t('farmers.edit-contacts.title') }), _jsx(IconEdit, { fontSize: "small" })] }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Skeleton, { variant: "circular", width: 28, height: 28 }), _jsx(Skeleton, { variant: "rounded", width: 100, height: 10 })] }), _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Skeleton, { variant: "circular", width: 28, height: 28 }), _jsx(Skeleton, { variant: "rounded", width: 100, height: 10 })] })] })] }));
    return (_jsxs("div", { className: classes.root, children: [displayAssignContactButton && (_jsxs(Button, { onClick: handleShowEditContactForm, variant: "text", sx: { p: 0, minHeight: 0, '&:hover': { background: 'none' } }, className: classes.editContactFormButton, children: [_jsx(IconPerson, {}), _jsx(Typography, { variant: "body1", sx: { textDecoration: 'underline', fontWeight: 'bold' }, children: t('farmers.edit-contacts.title') }), _jsx(IconEdit, { fontSize: "small" })] })), showEditContactForm ? (_jsx(ContactForm, { profileInvitationId: invitationId, farmId: farmId, assignedContacts: assignedContacts !== null && assignedContacts !== void 0 ? assignedContacts : [], assignableContacts: assignableContacts, onSave: handleHideEditContactForm, onCancel: handleHideEditContactForm })) : (_jsx(ContactList, { contacts: assignedContacts }))] }));
};
