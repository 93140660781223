var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAlarmingDataSidePanelStyles } from '@/pages/AlarmingDataPage/AlarmingDataSidePanel/AlarmingDataSidePanel.style';
import { FarmSeasonTitle } from '@features/farmSeason/FarmSeasonTitle/FarmSeasonTitle';
import { ROUTES } from '@router/routes';
import { generateRoute } from '@router/routes.utils';
import { Link } from 'react-router-dom';
import { AnalysisProfileCard } from '../AnalysisProfileCard/AnalysisProfileCard';
import { ROLE_GROUP, useUserRoleGroup } from '@hooks';
const AlarmingDataFarmSeason = (_a) => {
    var { className, userOrInvitationId, farmSeason, selected = false } = _a, rest = __rest(_a, ["className", "userOrInvitationId", "farmSeason", "selected"]);
    const { classes, cx } = useAlarmingDataSidePanelStyles();
    const { id, carbonProgrammeYear, harvestYear } = farmSeason;
    return (_jsx(Link, { className: cx(selected ? classes.selectedPanelLink : classes.panelLink, className), to: generateRoute(ROUTES.ALARMING_DATA, { id: userOrInvitationId, farmSeasonId: id }), children: _jsx("li", Object.assign({}, rest, { children: _jsx(FarmSeasonTitle, { carbonProgrammeYear: carbonProgrammeYear, harvestYear: harvestYear, "data-selected": selected }) })) }));
};
export const AlarmingDataSidePanel = ({ className, farmer, selectedFarmSeasonId }) => {
    var _a;
    const { classes, cx } = useAlarmingDataSidePanelStyles();
    const { firstName, lastName, userId } = farmer;
    const { roleGroup } = useUserRoleGroup();
    const isPartner = roleGroup === ROLE_GROUP.PARTNER;
    return (_jsxs("div", { className: cx(classes.sidePanel, className), children: [_jsx("ul", { className: classes.sidePanelLinksList, children: (_a = farmer.farmSeasons) === null || _a === void 0 ? void 0 : _a.map((farmSeason) => (_jsx(AlarmingDataFarmSeason, { selected: selectedFarmSeasonId === farmSeason.id, userOrInvitationId: isPartner ? farmer.invitationId : farmer.userId, farmSeason: farmSeason }, farmSeason.id))) }), _jsx(AnalysisProfileCard, { className: classes.profileCard, firstName: firstName, lastName: lastName, userId: userId })] }));
};
