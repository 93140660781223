var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDeleteFarmerFromPartnerMutation, useDeleteInvitationsMutation } from '@services';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks';
import { clearErrorType, ERRORS, setError } from '@/errorSlice';
export const useDeleteFarmer = ({ onSuccess, onError, } = {}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [deleteFarmer, { isLoading: isDeleteFarmerLoading }] = useDeleteFarmerFromPartnerMutation();
    const [deleteInvitations, { isLoading: isDeleteInvitationsLoading }] = useDeleteInvitationsMutation();
    const onDelete = (_a) => __awaiter(void 0, [_a], void 0, function* ({ farmerId, invitationId }) {
        try {
            if (!farmerId && !invitationId)
                return { success: false };
            const _res = farmerId
                ? yield deleteFarmer({ farmerId }).unwrap()
                : yield deleteInvitations({ ids: [invitationId] }).unwrap();
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            dispatch(clearErrorType(ERRORS.COULD_NOT_DELETE_FARMER));
            return { success: true };
        }
        catch (_error) {
            onError === null || onError === void 0 ? void 0 : onError();
            dispatch(setError({ type: ERRORS.COULD_NOT_DELETE_FARMER, message: t('errors.could-not-delete-farmer') }));
            return { success: false };
        }
    });
    const isLoading = isDeleteFarmerLoading || isDeleteInvitationsLoading;
    return { onDelete, isLoading };
};
