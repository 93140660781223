import { makeStyles } from 'tss-react/mui';
export const useAlarmingDataGroupedByResolutionStyles = makeStyles()((theme) => ({
    container: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        width: '100%',
    },
    description: {
        marginTop: theme.spacing(2),
    },
    yearTitle: {
        marginTop: theme.spacing(8),
    },
    tabs: {
        marginTop: theme.spacing(4),
    },
    tabsFlexContainer: {
        borderBottom: 'none',
        gap: theme.spacing(2),
    },
    tab: {
        padding: theme.spacing(2),
        minHeight: 'auto',
        borderRadius: '8px',
    },
    selectedTab: {
        backgroundColor: theme.palette.common.white,
    },
    list: {
        marginTop: theme.spacing(5),
    },
}));
