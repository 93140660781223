var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconCrossedOutEye = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", sx: { fill: 'none' }, color: color }, rest, { children: _jsx("path", { d: "M11.099 7.39488C11.388 7.35482 11.6861 7.33333 11.9932 7.33333C15.6232 7.33333 18.0053 10.3366 18.8055 11.5245C18.9024 11.6683 18.9508 11.7402 18.9779 11.8511C18.9983 11.9344 18.9982 12.0658 18.9779 12.1491C18.9508 12.2599 18.902 12.3323 18.8044 12.477C18.5912 12.7934 18.2661 13.2381 17.8355 13.7203M8.24143 8.47669C6.70402 9.45447 5.6603 10.8129 5.1815 11.5235C5.08421 11.6679 5.03556 11.7401 5.00844 11.851C4.98808 11.9343 4.98807 12.0656 5.00843 12.1489C5.03553 12.2598 5.08396 12.3317 5.18081 12.4755C5.98106 13.6634 8.36309 16.6667 11.9932 16.6667C13.4569 16.6667 14.7176 16.1784 15.7536 15.5177M5.59343 6L18.3929 18M10.4847 10.5858C10.0987 10.9477 9.85992 11.4477 9.85992 12C9.85992 13.1046 10.815 14 11.9932 14C12.5822 14 13.1156 13.7761 13.5016 13.4142", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
