var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePermission } from '@hooks/usePermission';
import { ConfirmFarmerRemovalModal } from '@features/farmer/delete/ConfirmFarmerRemovalModal';
import { useToggle } from '@hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@router/routes';
import { useDeleteFarmer } from '@features/farmer/delete/useDeleteFarmer';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
export const DeleteFarmerButton = ({ farmerId, invitationId }) => {
    const { canDeleteAllFarmers } = usePermission();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { open, handleOpen, handleClose } = useToggle();
    const handleDeleteSuccess = () => {
        navigate(ROUTES.FARMERS);
        handleClose();
    };
    const { onDelete, isLoading } = useDeleteFarmer({
        onError: () => enqueueSnackbar(t('errors.server-error'), { variant: 'error' }),
        onSuccess: () => {
            enqueueSnackbar(t('snackbar.farmer-deleted'), { variant: 'success' });
            handleDeleteSuccess();
        },
    });
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () { return onDelete({ farmerId, invitationId }); });
    return (_jsxs(_Fragment, { children: [canDeleteAllFarmers && (_jsx(Button, { onClick: handleOpen, startIcon: _jsx(DeleteIcon, { style: { fontSize: '16px' } }), color: "error", variant: "outlined", size: "small", children: t('farmers.remove-farmer') })), _jsx(ConfirmFarmerRemovalModal, { open: open, onClose: handleClose, onConfirm: handleDelete, isLoading: isLoading })] }));
};
