import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFarmerProfileTimelineStyle } from './FarmerProfileTimeline.style';
import { Timeline, TimelineStep, TimelineNode, TimelineSegment, TimelineItem, TimelineContent, TimelineLabel, } from '@components/Timeline';
import { FarmSeasonStatusPanel } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel';
import { useFarmerProfileTimelineViewModel } from '@features/farmer/profile/components/FarmerProfileTimeline/useFarmerProfileTimelineViewModel';
import { useIndicatorPosition } from '@hooks/useIndicatorPosition';
import { useEffect } from 'react';
import { COHORTS } from '@/types/carbonProgramme.types';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
export const FarmerProfileTimeline = ({ user, farmSeasons, }) => {
    const { t } = useTranslation();
    const { classes } = useFarmerProfileTimelineStyle();
    const { selectedFarmSeasonId, selectedFarmSeason, handleSelectFarmSeason, steps } = useFarmerProfileTimelineViewModel({ farmSeasons });
    const { indicatorPosition, updateIndicatorPosition, parentRef, triggerRefs } = useIndicatorPosition({ defaultPosition: 40 });
    // Update indicator position after current farm season is selected
    useEffect(() => {
        if (selectedFarmSeasonId) {
            const selectedStep = steps === null || steps === void 0 ? void 0 : steps.find((step) => step.farmSeasonId === selectedFarmSeasonId);
            if (selectedStep)
                updateIndicatorPosition(selectedStep.id.toString());
        }
    }, [selectedFarmSeasonId, steps]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.timelineContainer, children: _jsx(Timeline, { selectedStep: selectedFarmSeasonId, onStepSelect: handleSelectFarmSeason, children: steps.map((step, index) => {
                        var _a;
                        return (_jsxs(TimelineItem, { value: (_a = step.farmSeasonId) !== null && _a !== void 0 ? _a : index, status: step.status, children: [_jsx(Tooltip, { placement: "top", arrow: true, title: step.cohort === COHORTS.COHORT_1 && index === 0 ? t('errors.baseline-in-v1') : '', children: _jsx(Box, { component: "span", width: "100%", children: _jsxs(TimelineStep, { disabled: step.disabled, children: [_jsx(TimelineNode, { ref: (el) => (triggerRefs.current[step.id] = el), onClick: () => updateIndicatorPosition(step.id.toString()) }), index < steps.length - 1 && _jsx(TimelineSegment, {})] }) }) }), _jsx(TimelineContent, { children: _jsx(TimelineLabel, { align: "center", children: step.label }) })] }, step.id));
                    }) }) }), selectedFarmSeason && (_jsxs("div", { ref: parentRef, className: classes.farmSeasonPanelContainer, children: [_jsx("div", { className: classes.indicator, style: { left: `${indicatorPosition}px` } }), _jsx(FarmSeasonStatusPanel, { user: user, farmSeason: selectedFarmSeason })] }))] }));
};
