var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Link } from '@mui/material';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { usePermission } from '@hooks';
import { ROUTES } from '@router/routes';
export const VisitFarmButton = (_a) => {
    var { carbonModel, userId, farmSeasonId, isSubscribed, children } = _a, rest = __rest(_a, ["carbonModel", "userId", "farmSeasonId", "isSubscribed", "children"]);
    const { canVisitAssociateFarm, canVisitPartnerFarm, canVisitAssignedAssociateFarm, canVisitAssignedPartnerFarm } = usePermission();
    const canVisitFarm = canVisitAssociateFarm || canVisitPartnerFarm || canVisitAssignedAssociateFarm || canVisitAssignedPartnerFarm;
    const v2Path = `${process.env.FARMER_PORTAL_URL_V2}${ROUTES.VISIT_FARM}/${userId}`;
    const v3Path = `${process.env.FARMER_PORTAL_URL_V3}/${farmSeasonId}`;
    const path = carbonModel === CARBON_RESULT_MODEL.CFT ? v2Path : v3Path;
    return canVisitFarm && isSubscribed ? (_jsx(Button, Object.assign({ disabled: !isSubscribed, component: Link, href: path, target: "_blank", rel: "noreferrer" }, rest, { children: children }))) : (_jsx(_Fragment, {}));
};
