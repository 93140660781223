import { useCallback, useEffect, useMemo, useState } from 'react';
import { FARM_SEASON_STATUS } from '@/types/farmSeason.types';
import { capitalize } from '@mui/material';
import { TIMELINE_ITEM_STATUS } from '@components/Timeline';
import { useTranslation } from 'react-i18next';
import { COHORTS } from '@/types/carbonProgramme.types';
export const useFarmerProfileTimelineViewModel = ({ farmSeasons }) => {
    var _a;
    const { t } = useTranslation();
    const [selectedFarmSeasonId, setSelectedFarmSeasonId] = useState(undefined);
    const defaultSeason = ((_a = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[0]) === null || _a === void 0 ? void 0 : _a.cohort) === COHORTS.COHORT_1 ? farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[1] : farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons[0];
    const currentFarmSeason = useMemo(() => {
        var _a, _b;
        return (_b = (_a = farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.find((fs) => !!fs.id && fs.status !== FARM_SEASON_STATUS.RESULTS_PUBLISHED)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : defaultSeason === null || defaultSeason === void 0 ? void 0 : defaultSeason.id;
    }, [farmSeasons, defaultSeason === null || defaultSeason === void 0 ? void 0 : defaultSeason.id]);
    const selectedFarmSeason = useMemo(() => farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.find((fs) => fs.id === selectedFarmSeasonId), [farmSeasons, selectedFarmSeasonId]);
    const steps = useMemo(() => farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.map((farmSeason, index) => ({
        id: farmSeason.carbon_programme_year,
        farmSeasonId: (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id) ? Number(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id) : undefined,
        cohort: farmSeason.cohort,
        isSelected: farmSeason.id ? selectedFarmSeasonId === Number(farmSeason.id) : false,
        label: index === 0
            ? capitalize(`${t('common.baseline-season')} \n ${farmSeason.harvest_year}`)
            : capitalize(`${t('common.season')} ${index} \n ${farmSeason.harvest_year}`),
        status: farmSeason.status === FARM_SEASON_STATUS.RESULTS_PUBLISHED
            ? TIMELINE_ITEM_STATUS.DONE
            : farmSeason.status !== null
                ? TIMELINE_ITEM_STATUS.IN_PROGRESS
                : TIMELINE_ITEM_STATUS.NOT_STARTED,
        onClick: (id) => setSelectedFarmSeasonId(id),
        disabled: !farmSeason.is_baseline && !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.id),
    })), [farmSeasons]);
    const handleSelectFarmSeason = useCallback((value) => setSelectedFarmSeasonId(value), []);
    useEffect(() => {
        if ((farmSeasons === null || farmSeasons === void 0 ? void 0 : farmSeasons.length) && !selectedFarmSeasonId) {
            currentFarmSeason
                ? setSelectedFarmSeasonId(currentFarmSeason)
                : setSelectedFarmSeasonId(farmSeasons[farmSeasons.length - 1].id);
        }
    }, [farmSeasons, selectedFarmSeasonId]);
    return {
        farmSeasons,
        selectedFarmSeasonId,
        selectedFarmSeason,
        handleSelectFarmSeason,
        steps,
    };
};
