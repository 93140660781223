var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useTheme } from '@mui/material';
export const BoxedIcon = (_a) => {
    var _b, _c;
    var { icon, variant = 'neutral', sx } = _a, rest = __rest(_a, ["icon", "variant", "sx"]);
    const theme = useTheme();
    const variantBackgroundMapping = {
        success: theme.palette.success.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        info: theme.palette.primary.main,
        neutral: theme.palette.grey[200],
    };
    return (_jsx(Box, Object.assign({ sx: Object.assign({ borderRadius: '8px', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: (_c = (_b = theme.palette[variant]) === null || _b === void 0 ? void 0 : _b.contrastText) !== null && _c !== void 0 ? _c : 'black', backgroundColor: variantBackgroundMapping[variant] }, sx) }, rest, { children: icon })));
};
