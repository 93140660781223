var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconBack = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17.633 8.471", enableBackground: "new 0 0 17.633 8.471", color: color }, rest, { children: _jsx("path", { d: "M17.436,3.369H3.294l2.059-2.06c0.059-0.059,0.059-0.153,0-0.212l-1.01-1.01C4.316,0.059,4.278,0.045,4.238,0.045\n\tS4.16,0.06,4.132,0.089l-4.041,4.04c-0.059,0.058-0.059,0.152,0,0.212l4.041,4.042C4.16,8.409,4.198,8.427,4.238,8.427\n\ts0.078-0.018,0.106-0.044l1.01-1.012c0.059-0.059,0.059-0.153,0-0.213l-2.06-2.059h14.142c0.082,0,0.15-0.067,0.15-0.15v-1.43\n\tC17.586,3.436,17.518,3.369,17.436,3.369z" }) })));
};
IconBack.displayName = 'IconBack';
