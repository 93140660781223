import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { usePermission } from '@hooks/usePermission';
import { PartnerContactsEditForm } from '@features/assignment/PartnerContactEditForm/PartnerContactsEditForm';
import { AssociateContactEditForm } from '@features/assignment/AssociateContactEditForm/AssociateContactEditForm';
export const ContactForm = ({ assignedContacts, assignableContacts, profileInvitationId, farmId, onSave, onCancel, }) => {
    const { canAssignAssociateToFarmer, canAssignPartnerMemberToFarmer } = usePermission();
    if (canAssignPartnerMemberToFarmer && profileInvitationId)
        return (_jsx(PartnerContactsEditForm, { assignedContacts: assignedContacts, assignableContacts: assignableContacts, profileInvitationId: profileInvitationId, onSave: onSave, onCancel: onCancel }));
    if (canAssignAssociateToFarmer && farmId)
        return (_jsx(AssociateContactEditForm, { assignedContacts: assignedContacts, assignableContacts: assignableContacts, farmId: farmId, onSave: onSave, onCancel: onCancel }));
    return _jsx(_Fragment, {});
};
