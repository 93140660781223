import { ApiToggle, useGetFarmSeasonsQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePermission } from '@hooks';
export const useGetFarmSeasons = (props) => {
    const { canSeeAllPartnerFarmers, canSeeAssignedPartnerFarmers, canSeeAssociateFarmers, canSeeAssociateAssignedFarmers, } = usePermission();
    const partnerCanFetchProfile = (canSeeAllPartnerFarmers || canSeeAssignedPartnerFarmers) && 'invitationId' in props;
    const associateCanFetchProfile = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && 'userId' in props;
    const fetchParam = partnerCanFetchProfile
        ? { toggle: ApiToggle.PARTNER, invitationId: props.invitationId }
        : associateCanFetchProfile
            ? { toggle: ApiToggle.ASSOCIATE, userId: props.userId }
            : skipToken;
    return useGetFarmSeasonsQuery(fetchParam);
};
