export const CARBON_MODEL = {
    DNDC: 'DNDC',
    CFT: 'CFT',
};
export const RESULTS_CALCULATION_STATUS = {
    UNPROCESSED: 'UNPROCESSED',
    ONGOING: 'ONGOING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
};
export const FARM_SEASON_STATUS = {
    PENDING_RENEWAL: 'PENDING_RENEWAL',
    DATA_INPUT_NOT_STARTED: 'DATA_INPUT_NOT_STARTED',
    DATA_INPUT_IN_PROGRESS: 'DATA_INPUT_IN_PROGRESS',
    DATA_INPUT_VALIDATED: 'DATA_INPUT_VALIDATED',
    WAITING_FOR_RESULTS: 'WAITING_FOR_RESULTS',
    RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
    RESULTS_PUBLISHED: 'RESULTS_PUBLISHED',
};
export const AUDIT_STATUS = {
    DONE: 'done',
    IN_PROGRESS: 'in_progress',
};
