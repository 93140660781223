var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import EmailIcon from '@mui/icons-material/Email';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
export const IconLetter = (_a) => {
    var { color = 'primary', variant = 'full' } = _a, rest = __rest(_a, ["color", "variant"]);
    if (variant === 'outlined')
        return _jsx(EmailIconOutlined, Object.assign({ color: color }, rest));
    return _jsx(EmailIcon, Object.assign({ color: color }, rest));
};
IconLetter.displayName = 'IconLetter';
