var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconGraph = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ viewBox: "0 0 24 22", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: _jsx("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", children: _jsx("g", { transform: "translate(-28.000000, -964.000000)", fill: "currentColor", children: _jsx("g", { transform: "translate(10.000000, 945.000000)", children: _jsxs("g", { transform: "translate(18.000000, 19.000000)", children: [_jsx("path", { d: "M8,8 L4,8 L4,16 L8,16 L8,8 Z M4,6 L8,6 C9.1045695,6 10,6.8954305 10,8 L10,18 L2,18 L2,8 C2,6.8954305 2.8954305,6 4,6 Z" }), _jsx("path", { d: "M14,2 L10,2 L10,16 L14,16 L14,2 Z M10,0 L14,0 C15.1045695,0 16,0.8954305 16,2 L16,18 L8,18 L8,2 C8,0.8954305 8.8954305,0 10,0 Z" }), _jsx("path", { d: "M20,10 L16,10 L16,16 L20,16 L20,10 Z M16,8 L20,8 C21.1045695,8 22,8.8954305 22,10 L22,18 L14,18 L14,10 C14,8.8954305 14.8954305,8 16,8 Z" }), _jsx("rect", { x: "0", y: "20", width: "24", height: "2", rx: "1" })] }) }) }) }) })));
};
IconGraph.displayName = 'IconGraph';
