import { useGetAlarmingDataQuery } from '@services';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePermission } from '@hooks';
export const useGetFarmSeasonAgroChecks = ({ farmSeasonId, processHistory = false, }) => {
    const { canSeeAlarmingData, canSeePartnerAlarmingData, canSeeAssignedPartnerAlarmingData, canSeeAssignedAlarmingData, } = usePermission();
    const canSeeAgroChecks = canSeeAlarmingData ||
        canSeePartnerAlarmingData ||
        canSeeAssignedPartnerAlarmingData ||
        canSeeAssignedAlarmingData;
    const { data: agroChecks, isLoading: isLoadingAgroChecks } = useGetAlarmingDataQuery(canSeeAgroChecks && farmSeasonId ? { farmSeasonId: farmSeasonId, processHistory } : skipToken);
    return { canSeeAgroChecks, agroChecks: agroChecks !== null && agroChecks !== void 0 ? agroChecks : [], isLoadingAgroChecks };
};
