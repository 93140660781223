const buttonCss = {
    root: ({ theme }) => ({
        textTransform: 'none',
        borderRadius: '10px',
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        lineHeight: 1,
        minHeight: 42,
    }),
    text: {
        fontSize: '14px',
    },
    contained: {
        '&:hover': {},
    },
    containedPrimary: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset', // allow :hover styles to be triggered
            cursor: 'not-allowed',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.primary.main,
        ':hover': {
            background: theme.palette.primary.dark,
        },
    }),
    containedSecondary: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset', // allow :hover styles to be triggered
            cursor: 'not-allowed',
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.secondary.main,
        ':hover': {
            background: theme.palette.secondary.dark,
        },
    }),
    containedInfo: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset', // allow :hover styles to be triggered
            cursor: 'not-allowed',
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.info.main,
        ':hover': {
            background: theme.palette.info.dark,
        },
    }),
    containedSuccess: ({ theme }) => ({
        '&.Mui-disabled': {
            pointerEvents: 'unset', // allow :hover styles to be triggered
            cursor: 'not-allowed',
            background: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            opacity: 0.5,
        },
        background: theme.palette.success.main,
        ':hover': {
            background: theme.palette.success.dark,
        },
    }),
    sizeSmall: ({ theme }) => ({
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minHeight: 32,
    }),
};
const buttonProperties = {
    variant: 'contained',
    disableElevation: true,
};
const MuiButton = { styleOverrides: buttonCss, defaultProps: buttonProperties };
export default MuiButton;
