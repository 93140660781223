import { useRef, useState } from 'react';
export const useIndicatorPosition = ({ defaultPosition = 0, } = {}) => {
    const [indicatorPosition, setIndicatorPosition] = useState(defaultPosition);
    const parentRef = useRef(null);
    const triggerRefs = useRef({});
    const updateIndicatorPosition = (key) => {
        var _a;
        const parentElement = parentRef.current;
        const triggerElement = triggerRefs.current[key];
        if (parentElement && triggerElement) {
            const { left, width } = triggerElement.getBoundingClientRect();
            const parentLeft = (_a = parentElement === null || parentElement === void 0 ? void 0 : parentElement.getBoundingClientRect().left) !== null && _a !== void 0 ? _a : 0;
            setIndicatorPosition(left - parentLeft + width / 2);
        }
    };
    return {
        indicatorPosition,
        parentRef,
        triggerRefs,
        updateIndicatorPosition,
    };
};
