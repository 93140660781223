import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import { useContactsEditFormStyles } from './ContactsEditForm.style';
import { SelectChips } from '@components/Form/SelectChips/SelectChips';
export const ContactsEditForm = ({ assignedContacts, onContactChange, assignableContacts, onCancel, onSubmit, isLoading, }) => {
    const { t } = useTranslation();
    const { classes } = useContactsEditFormStyles();
    const handleChange = (values) => onContactChange(values);
    const getContactKey = (contact) => contact.id;
    const getContactLabel = (contact) => `${contact.first_name} ${contact.last_name}`;
    const getContactValue = (contact) => contact.id;
    const handleChipDelete = (value) => onContactChange(assignedContacts.filter((contact) => contact.id !== value.id));
    return (_jsxs("div", { className: classes.container, children: [_jsx(SelectChips, { className: classes.select, label: t('farmers.profile.about.edit.input-label'), values: assignedContacts, options: assignableContacts, onChange: handleChange, onChipDelete: handleChipDelete, getOptionKey: getContactKey, getOptionLabel: getContactLabel, getOptionValue: getContactValue, shouldGrow: false, maxChips: 2 }), _jsxs("div", { children: [_jsx(Button, { disabled: isLoading, variant: "contained", onClick: onSubmit, children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.save') }), _jsx(Button, { variant: "text", onClick: onCancel, children: t('buttons.cancel') })] })] }));
};
