import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton } from '@mui/material';
import { FarmerProfileTopNav } from '@features/farmer/profile/components/FarmerProfileTopNav/FarmerProfileTopNav';
import { useFarmerProfilePageStyles } from '@/pages/FarmerProfilePage/FarmerProfilePage.style';
export const FarmerProfilePageSkeleton = () => {
    const { classes } = useFarmerProfilePageStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsx(FarmerProfileTopNav, {}), _jsxs("div", { className: classes.farmerProfileSection, children: [_jsx(Skeleton, { variant: "rounded", animation: "wave", width: "100%", height: 300 }), _jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 10,
                            width: 816,
                            ml: 'auto',
                            mr: 'auto',
                        }, children: [_jsxs(Box, { sx: { width: '20%', display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { width: 32, height: 32, variant: "circular" }), _jsx(Skeleton, { width: "calc(100% - 32px)", height: 3, variant: "rectangular" })] }), _jsxs(Box, { sx: { width: '20%', display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { width: 32, height: 32, variant: "circular" }), _jsx(Skeleton, { width: "calc(100% - 32px)", height: 3, variant: "rectangular" })] }), _jsxs(Box, { sx: { width: '20%', display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { width: 32, height: 32, variant: "circular" }), _jsx(Skeleton, { width: "calc(100% - 32px)", height: 3, variant: "rectangular" })] }), _jsxs(Box, { sx: { width: '20%', display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { width: 32, height: 32, variant: "circular" }), _jsx(Skeleton, { width: "calc(100% - 32px)", height: 3, variant: "rectangular" })] }), _jsxs(Box, { sx: { width: '20%', display: 'flex', alignItems: 'center' }, children: [_jsx(Skeleton, { width: 32, height: 32, variant: "circular" }), _jsx(Skeleton, { width: "calc(100% - 32px)", height: 3, variant: "rectangular" })] }), _jsx(Skeleton, { width: 32, height: 32, variant: "circular" })] }), _jsx(Skeleton, { width: 816, height: 280, variant: "rounded", sx: { mt: 10, mr: 'auto', ml: 'auto' } })] })] }));
};
