import { makeStyles } from 'tss-react/mui';
export const useFarmSeasonStatusPanelStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(6),
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    statusesContainer: {
        marginTop: theme.spacing(10),
    },
    item: {
        width: '100%',
    },
    content: {
        width: '100%',
    },
    status: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(8),
        width: '100%',
    },
    statusInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'flex-start',
    },
    statusDateAndAuthor: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    date: {
        fontWeight: 'bold',
    },
    author: {},
    statusActions: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'flex-start',
    },
    agroChecksLoadingContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}));
