import { makeStyles } from 'tss-react/mui';
export const useDeferredPaymentBannerStyles = makeStyles()((theme) => {
    return {
        container: {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            marginTop: theme.spacing(10),
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
            fontFamily: theme.typography.fontFamily,
            width: '820px',
        },
        wrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        box: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            padding: '8px',
        },
        icon: { fill: theme.palette.common.white, fontSize: 'medium' },
        switch: {
            width: 42,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(20px)',
                    color: theme.palette.common.white,
                    '& + .MuiSwitch-track': {
                        backgroundColor: theme.palette.primary.main,
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                    '& .MuiSwitch-thumb': {
                        backgroundColor: theme.palette.common.white,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[400],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.7,
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 16,
                height: 16,
                color: theme.palette.grey[400],
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.grey[400]}`,
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
            },
        },
    };
});
