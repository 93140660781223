var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useId, useRef, useState, } from 'react';
import { composeEventHandlers, createContextScope } from '@utils';
import { useTabsStyles } from '@components/Tabs/Tabs.style';
import { useControllableState } from '@hooks';
import { Button } from '@mui/material';
import { useComposedRefs } from '@hooks/useComposeRefs';
const TABS_SCOPE_NAME = 'Tabs';
const [createTabsContext, createTabsScope] = createContextScope(TABS_SCOPE_NAME);
const [TabsProvider, useTabsContext] = createTabsContext(TABS_SCOPE_NAME);
const Tabs = forwardRef((_a, ref) => {
    var { __scopeTabs, defaultValue, value, onTabChange, children, className } = _a, rest = __rest(_a, ["__scopeTabs", "defaultValue", "value", "onTabChange", "children", "className"]);
    const a11yId = useId();
    const { classes, cx } = useTabsStyles();
    const tabsListRef = useRef(null);
    const [selectedTab, setSelectedTab] = useControllableState({
        prop: value,
        defaultProp: defaultValue,
        onChange: onTabChange,
    });
    return (_jsx(TabsProvider, { scope: __scopeTabs, a11yId: a11yId, tabsListRef: tabsListRef, selectedTab: selectedTab !== null && selectedTab !== void 0 ? selectedTab : '', setSelectedTab: setSelectedTab, children: _jsx("div", Object.assign({ ref: ref, className: cx(classes.tabs, className) }, rest, { children: children })) }));
});
Tabs.displayName = 'Tabs';
/* -------------------------------------------------------------------------------------------------
 * TabList
 * -----------------------------------------------------------------------------------------------*/
const TABS_LIST_SCOPE_NAME = 'TabsList';
const TabsList = forwardRef((_a, ref) => {
    var { __scopeTabs, className, children } = _a, rest = __rest(_a, ["__scopeTabs", "className", "children"]);
    const { classes, cx } = useTabsStyles();
    const { tabsListRef } = useTabsContext(TABS_LIST_SCOPE_NAME, __scopeTabs);
    const composedRefs = useComposedRefs(ref, tabsListRef);
    return (_jsx("div", Object.assign({ ref: composedRefs, role: "tablist", "aria-orientation": "horizontal", className: cx(classes.tabsList, className) }, rest, { children: children })));
});
TabsList.displayName = 'TabsList';
/* -------------------------------------------------------------------------------------------------
 * Tab
 * -----------------------------------------------------------------------------------------------*/
const TAB_SCOPE_NAME = 'Tab';
const Tab = forwardRef((_a, ref) => {
    var { __scopeTabs, children, value, onClick, disabled = false, className } = _a, rest = __rest(_a, ["__scopeTabs", "children", "value", "onClick", "disabled", "className"]);
    const { classes, cx } = useTabsStyles();
    const { setSelectedTab, selectedTab, a11yId } = useTabsContext(TAB_SCOPE_NAME, __scopeTabs);
    const isSelected = selectedTab === value;
    return (
    // eslint-disable-next-line
    // @ts-ignore
    _jsx(Button, Object.assign({ size: "small", ref: ref, type: "button", variant: "text", role: "tab", "aria-selected": isSelected, "data-state": isSelected ? 'active' : 'inactive', "data-disabled": disabled ? '' : undefined, id: `${a11yId}-tab-${value}`, "aria-controls": `${a11yId}-tabpanel-${value}`, className: cx(classes.tab, isSelected && classes.selectedTab, className), onClick: composeEventHandlers(onClick, () => setSelectedTab(value)), disabled: disabled }, rest, { children: children })));
});
Tab.displayName = 'Tab';
/* -------------------------------------------------------------------------------------------------
 * TabPanel
 * -----------------------------------------------------------------------------------------------*/
const TAB_PANEL_SCOPE_NAME = 'TabPanel';
const TabPanel = forwardRef((_a, ref) => {
    var { __scopeTabs, value, className, children } = _a, rest = __rest(_a, ["__scopeTabs", "value", "className", "children"]);
    const { classes, cx } = useTabsStyles();
    const { selectedTab, a11yId } = useTabsContext(TAB_PANEL_SCOPE_NAME, __scopeTabs);
    return (_jsx("div", Object.assign({ id: `${a11yId}-tabpanel-${value}`, ref: ref, hidden: selectedTab !== value, className: cx(classes.tabPanel, className) }, rest, { children: selectedTab === value && children })));
});
TabPanel.displayName = 'TabPanel';
/* -------------------------------------------------------------------------------------------------
 * TabIndicator
 * -----------------------------------------------------------------------------------------------*/
const TAB_INDICATOR_SCOPE_NAME = 'TabIndicator';
const TabIndicator = forwardRef((_a, ref) => {
    var { __scopeTabs, className, style } = _a, rest = __rest(_a, ["__scopeTabs", "className", "style"]);
    const { classes, cx } = useTabsStyles();
    const { selectedTab, tabsListRef } = useTabsContext(TAB_INDICATOR_SCOPE_NAME, __scopeTabs);
    const [triangleLeft, setTriangleLeft] = useState(16);
    useEffect(() => {
        const tabsListElement = getElementFromRef(tabsListRef);
        if (tabsListElement) {
            const activeTab = tabsListElement.querySelector('[data-state="active"]');
            if (activeTab instanceof HTMLElement) {
                const tabRect = activeTab.getBoundingClientRect();
                const tabsListRect = tabsListElement.getBoundingClientRect();
                const newLeft = tabRect.left - tabsListRect.left + tabRect.width / 2 - 10;
                setTriangleLeft(newLeft);
            }
        }
    }, [selectedTab]);
    return (_jsx("div", Object.assign({ ref: ref, className: cx(classes.tabIndicator, className), style: Object.assign({ left: `${triangleLeft}px`, transition: 'left 0.3s ease' }, style) }, rest)));
});
TabIndicator.displayName = 'TabIndicator';
const getElementFromRef = (ref) => {
    var _a;
    if (typeof ref === 'function') {
        // If it's a callback ref, assume it was set to an element previously
        let element = null;
        ref(((el) => (element = el || null)));
        return element;
    }
    return (_a = ref === null || ref === void 0 ? void 0 : ref.current) !== null && _a !== void 0 ? _a : null;
};
export { createTabsScope, Tabs, TabsList, Tab, TabPanel, TabIndicator };
