import { makeStyles } from 'tss-react/mui';
export const useContactListStyles = makeStyles()((theme) => ({
    list: {},
    contact: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    avatar: {
        width: 28,
        height: 28,
        fontSize: 12,
        backgroundColor: '#F6F3E5',
        color: theme.palette.secondary.main,
    },
}));
