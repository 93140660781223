import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileTimelineStyle = makeStyles()((theme) => {
    return {
        timelineContainer: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        },
        farmSeasonInfosContainer: {
            position: 'relative',
            marginTop: theme.spacing(20),
            borderTop: `2px solid ${theme.palette.success.dark}`,
            overflow: 'visible',
        },
        farmSeasonPanelContainer: {
            marginTop: theme.spacing(20),
            position: 'relative',
            borderTop: `2px solid ${theme.palette.success.dark}`,
            borderRadius: theme.shape.borderRadius,
        },
        indicator: {
            content: '""',
            position: 'absolute',
            top: '-25px',
            width: 0,
            height: 0,
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderBottom: `25px solid ${theme.palette.success.dark}`,
            transition: 'left 0.3s ease',
            transform: 'translateX(-50%)',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '3px',
                left: '-14px',
                width: 0,
                height: 0,
                borderLeft: '14px solid transparent',
                borderRight: '14px solid transparent',
                borderBottom: `24px solid ${theme.palette.grey[50]}`,
            },
        },
    };
});
