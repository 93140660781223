var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, ApiToggle } from '@services';
import i18n from '../i18n';
export const memberApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMembers: builder.query({
            queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                return __awaiter(this, void 0, void 0, function* () {
                    let result = [];
                    if (arg.toggle === ApiToggle.PARTNER) {
                        const partnerResult = yield fetchWithBQ(`/private/members`);
                        result = partnerResult.data;
                    }
                    else if (arg.toggle === ApiToggle.ASSOCIATE) {
                        const associateResult = yield fetchWithBQ('/private/members/associate');
                        result = associateResult.data;
                    }
                    return { data: result };
                });
            },
            providesTags: ['Members'],
        }),
        resendInvites: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/resend?lang=${i18n.language}`,
                method: 'PUT',
                body: data.ids,
            }),
            invalidatesTags: ['Members'],
        }),
        deleteInvitations: builder.mutation({
            query: (data) => ({
                url: `/private/invitations${data.teamId ? `?teamId=${data.teamId}` : ''}`,
                method: 'DELETE',
                body: data.ids,
            }),
            invalidatesTags: ['Members'],
        }),
        updateMemberInvitationTeams: builder.mutation({
            query: (data) => ({
                url: `/private/invitations/${data.id}/teams`,
                method: 'PUT',
                body: data.team_ids,
            }),
            invalidatesTags: ['Members'],
        }),
    }),
});
export const { useGetMembersQuery, useDeleteInvitationsMutation, useResendInvitesMutation, useUpdateMemberInvitationTeamsMutation, } = memberApi;
