var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useConfirmAlarmingDataMutation } from '@services';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks';
import { clearErrorType, ERRORS, setError } from '@/errorSlice';
export const useConfirmAgroCheck = ({ onSuccess, onError } = {}) => {
    const [confirmAgroCheckMutation, { isLoading: isConfirmingAgroCheck, isSuccess, isError }] = useConfirmAlarmingDataMutation();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const confirmAgroCheck = (alarmingDataIds) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield confirmAgroCheckMutation({ alarmingDataIds });
            dispatch(clearErrorType(ERRORS.COULD_NOT_CONFIRM_AGRO_CHECKS));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
        catch (_e) {
            dispatch(setError({
                type: ERRORS.COULD_NOT_CONFIRM_AGRO_CHECKS,
                message: t('errors.could-not-confirm-agro-check'),
            }));
            onError === null || onError === void 0 ? void 0 : onError();
        }
    });
    return { confirmAgroCheck, isConfirmingAgroCheck, isSuccess, isError };
};
