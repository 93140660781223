import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Modal, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConfirmFarmerRemovalModalStyles } from '@features/farmer/delete/ConfirmFarmerRemovalModal.style';
export const MODAL_TEST_ID = 'confirm-farmer-removal-modal';
export const ConfirmFarmerRemovalModal = ({ open, onClose, onConfirm, isLoading = false, }) => {
    const { classes } = useConfirmFarmerRemovalModalStyles();
    const { t } = useTranslation();
    return (_jsx(Modal, { open: open, onClose: onClose, "data-testid": MODAL_TEST_ID, "aria-labelledby": "Confirm-Farmer-Removal-Settings", "aria-describedby": "Confirm-Farmer-Removal-Settings-Modal", children: _jsxs(Box, { className: classes.contentContainer, children: [_jsx(Typography, { align: "center", variant: "h2", className: classes.title, children: t('titles.farmer-confirm-removal-modal') }), _jsx(Typography, { variant: "subtitle1", className: classes.title, children: t('farmers.delete-farmer.modal.description') }), _jsxs(Box, { display: "flex", justifyContent: "flex-end", className: classes.buttonsContainer, children: [_jsx(Button, { onClick: onClose, variant: "outlined", className: classes.cancelBtn, children: t('buttons.cancel') }), _jsx(Button, { color: "error", onClick: onConfirm, startIcon: _jsx(DeleteIcon, {}), children: isLoading ? _jsx(CircularProgress, { size: 20 }) : t('buttons.remove') })] })] }) }));
};
