import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlarmingDataGroupedByType } from '@features/alarmingData/components/AlarmingDataGroupedByType/AlarmingDataGroupedByType';
import { FarmSeasonTitle } from '@features/farmSeason/FarmSeasonTitle/FarmSeasonTitle';
import { Typography } from '@mui/material';
import { LoadingPage } from '@pages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlarmingDataGroupedByResolutionStyles } from './AlarmingDataGroupedByResolution.style';
import { Tabs, Tab, TabsList, TabPanel } from '@components/Tabs';
export const AlarmingDataGroupedByResolution = ({ className, farmSeason, isAlarmingDataFetching, alarmingData, }) => {
    const { t } = useTranslation();
    const { classes, cx } = useAlarmingDataGroupedByResolutionStyles();
    const { carbonProgrammeYear, harvestYear } = farmSeason;
    const [selectedTab, setSelectedTab] = useState('unresolved');
    const unresolvedAlarmingDataCount = alarmingData.unresolved.count;
    const resolvedAlarmingDataCount = alarmingData.resolved.count;
    return (_jsxs("main", { className: cx(classes.container, className), children: [isAlarmingDataFetching && _jsx(LoadingPage, {}), _jsx(Typography, { color: "secondary", variant: "h3", children: t('titles.data-analysis') }), _jsx(Typography, { className: classes.description, variant: "body1", children: t('alarming-data.verify') }), _jsx(FarmSeasonTitle, { className: classes.yearTitle, carbonProgrammeYear: carbonProgrammeYear, harvestYear: harvestYear, TypographyProps: { variant: 'h2' } }), _jsxs(Tabs, { className: classes.tabs, value: selectedTab, onTabChange: (value) => setSelectedTab(value), children: [_jsxs(TabsList, { children: [_jsx(Tab, { value: "unresolved", children: t('alarming-data.tabs.todo', { count: unresolvedAlarmingDataCount }) }), _jsx(Tab, { value: "resolved", children: t('alarming-data.tabs.completed', { count: resolvedAlarmingDataCount }) })] }), _jsx(TabPanel, { value: "unresolved", children: _jsx(AlarmingDataGroupedByType, { className: classes.list, data: alarmingData.unresolved }) }), _jsx(TabPanel, { value: "resolved", children: _jsx(AlarmingDataGroupedByType, { className: classes.list, data: alarmingData.resolved }) })] })] }));
};
