var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconMinus = (_a) => {
    var { color = 'primary' } = _a, rest = __rest(_a, ["color"]);
    return (_jsx(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 10.3 10.3", enableBackground: "new 0 0 10.3 10.3", color: color }, rest, { children: _jsx("path", { d: "M10.15,4.287l-10-0.001C0.067,4.287,0,4.354,0,4.437v1.428c0,0.084,0.067,0.15,0.15,0.15h9.999\n\tc0.084,0,0.149-0.066,0.149-0.15V4.437C10.3,4.354,10.234,4.287,10.15,4.287z" }) })));
};
IconMinus.displayName = 'IconMinus';
