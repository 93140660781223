var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
export const IconCheckCircle = (_a) => {
    var { color = 'primary', variant = 'full' } = _a, rest = __rest(_a, ["color", "variant"]);
    if (variant === 'full')
        return (_jsxs(SvgIcon, Object.assign({ viewBox: "0 0 22 22", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: [_jsx("circle", { cx: "11", cy: "11", r: "9", fill: "currentColor", stroke: "currentColor", strokeWidth: "2" }), _jsx("path", { d: "M13.42 8.1L9.88 11.74 8.61 10.54C8.21 10.16 7.58 10.17 7.2 10.55C6.82 10.93 6.82 11.55 7.2 11.93L9.88 14.52L14.81 9.5C15.19 9.11 15.19 8.48 14.8 8.1C14.42 7.72 13.81 7.72 13.42 8.1Z", fill: "white" })] })));
    return (_jsxs(SvgIcon, Object.assign({ viewBox: "0 0 22 22", xmlns: "http://www.w3.org/2000/svg", color: color }, rest, { children: [_jsx("circle", { cx: "11", cy: "11", r: "9", fill: "none", stroke: "currentColor", strokeWidth: "2" }), _jsx("path", { d: "M13.42 8.1L9.88 11.74 8.61 10.54C8.21 10.16 7.58 10.17 7.2 10.55C6.82 10.93 6.82 11.55 7.2 11.93L9.88 14.52L14.81 9.5C15.19 9.11 15.19 8.48 14.8 8.1C14.42 7.72 13.81 7.72 13.42 8.1Z", fill: "currentColor" })] })));
};
IconCheckCircle.displayName = 'IconCheckCircle';
