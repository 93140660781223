import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TIMELINE_ITEM_STATUS, TimelineContent, TimelineItem, TimelineLabel, TimelineSegment, TimelineStep, } from '@components/Timeline';
import { Typography } from '@mui/material';
import { useFarmSeasonStatusPanelStyles } from '@features/farmer/profile/components/FarmSeasonStatusPanel/FarmSeasonStatusPanel.style';
import { useTranslation } from 'react-i18next';
export const FarmerProfileTimelineStep = ({ show, icon, label, statusText, author, extra, isStepCompleted, isPreviousStepCompleted, isLastStep = false, }) => {
    const { classes } = useFarmSeasonStatusPanelStyles();
    const { t } = useTranslation();
    return show ? (_jsxs(TimelineItem, { status: isStepCompleted ? TIMELINE_ITEM_STATUS.DONE : TIMELINE_ITEM_STATUS.NOT_STARTED, className: classes.item, children: [_jsxs(TimelineStep, { children: [icon, !isLastStep && _jsx(TimelineSegment, {})] }), _jsx(TimelineContent, { className: classes.content, children: _jsxs("div", { className: classes.status, children: [_jsxs("div", { className: classes.statusInfo, children: [_jsx(TimelineLabel, { sx: isPreviousStepCompleted
                                        ? { color: 'primary.main' }
                                        : { color: (theme) => theme.palette.neutral[400] }, children: label }), _jsxs("div", { className: classes.statusDateAndAuthor, children: [_jsx(Typography, { className: classes.date, variant: "body2", children: statusText }), author && (_jsxs(Typography, { className: classes.author, variant: "body2", children: [t('constants.by'), " ", author] }))] })] }), isPreviousStepCompleted && extra] }) })] })) : (_jsx(_Fragment, {}));
};
