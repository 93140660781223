import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Select } from '@components/Select';
import { useGetPartners } from '@hooks/useGetPartners';
import { Skeleton } from '@mui/material';
export const PartnerFilter = ({ values, onChange }) => {
    const { t } = useTranslation();
    const { data: partners, isLoading } = useGetPartners();
    const handleChange = (values) => onChange(values);
    const getOptionLabel = (partner) => partner.name;
    const getOptionValue = (partner) => partner.id;
    if (isLoading)
        return _jsx(Skeleton, { width: 95, height: 40, variant: "rounded" });
    if (!partners)
        return _jsx(_Fragment, {});
    return (_jsx(Select, { values: values !== null && values !== void 0 ? values : [], onChange: handleChange, options: partners, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (partners, allAreSelected) => (_jsx(Select.LabelWithPill, { label: t('common.partner'), selected: partners.length, areAllSelected: allAreSelected })), MenuProps: { autoFocus: true }, renderOption: (option, props) => _jsx(Select.CheckboxOption, Object.assign({}, props, { label: option.name })), renderOptionAll: (props) => _jsx(Select.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};
