import { makeStyles } from 'tss-react/mui';
export const useFarmerProfileContactsStyle = makeStyles()(() => ({
    root: {
        width: '100%',
    },
    editContactFormButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginBottom: '4px',
    },
}));
