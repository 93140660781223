var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@hooks';
import { clearErrorType, ERRORS, setError } from '@/errorSlice';
import { useVerifyEncodingMutation } from '@services';
export const useVerifyEncoding = ({ onSuccess, onError } = {}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [verifyEncodingMutation, { isLoading: isVerifyingEncoding, isSuccess, isError }] = useVerifyEncodingMutation();
    const verifyEncoding = (_a) => __awaiter(void 0, [_a], void 0, function* ({ farmSeasonId }) {
        try {
            const res = yield verifyEncodingMutation({ farmSeasonId: farmSeasonId }).unwrap();
            dispatch(clearErrorType(ERRORS.COULD_NOT_VERIFY_ENCODING));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            return { success: true, farmSeason: res };
        }
        catch (_e) {
            dispatch(setError({ type: ERRORS.COULD_NOT_VERIFY_ENCODING, message: t('errors.could-not-verify-encoding') }));
            onError === null || onError === void 0 ? void 0 : onError();
            return { success: false, farmSeason: null };
        }
    });
    return { verifyEncoding, isVerifyingEncoding, isSuccess, isError };
};
